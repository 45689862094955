
















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
    name: 'SearchSuggestion',

    props: {
        to: {
            type: String,
            required: true,
        },

        active: {
            type: Boolean,
            default: false,
        },
    },
})
