




















































import {
    defineComponent,
    onMounted,
    onUnmounted,
    PropType,
    ref,
} from '@nuxtjs/composition-api'
import dialogPolyfill from 'dialog-polyfill'
import { ModalDialogSize } from '@/types/modal'

/**
 * HTMLDialogElement is deprecated
 * we create own type because showModal or close property does not exist on type HTMLDialogElement
 * https://github.com/microsoft/TypeScript/issues/48267
 */
interface RealHTMLDialogElement extends HTMLDialogElement {
    showModal: () => void
    close: () => void
}

export default defineComponent({
    name: 'ModalDialog',
    props: {
        id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
        hideHeader: {
            type: Boolean,
            default: false,
        },
        hideFooter: {
            type: Boolean,
            default: true,
        },
        headerClass: {
            type: String,
            required: false,
        },
        footerClass: {
            type: String,
            required: false,
        },
        titleClass: {
            type: String,
            required: false,
        },
        size: {
            type: String as PropType<ModalDialogSize>,
            required: false,
        },
        noCloseOnBackdrop: {
            type: Boolean,
            default: false,
        },
        noCloseOnEsc: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['close'],
    setup(props, { emit }) {
        const dialog = ref<RealHTMLDialogElement>()
        const { classList } = document.body

        onMounted(() => {
            if (props.noCloseOnEsc) {
                dialog.value?.addEventListener('cancel', (event) => {
                    event.preventDefault()
                })
            }

            if (dialog.value) {
                if (!('showModal' in dialog.value)) {
                    const fallbackDialog: HTMLDialogElement = dialog.value
                    dialogPolyfill.registerDialog(fallbackDialog)
                    fallbackDialog.classList.add('polyfill-dialog')
                }

                dialog.value.showModal()
                classList.add('dialog-open')

                if (!props.noCloseOnEsc) {
                    dialog.value?.addEventListener('cancel', () => {
                        emit('close')
                    })
                }
            }
        })

        const closeModal = () => {
            dialog.value?.classList.add('hide')

            if (!dialog.value) return
            dialog.value.onanimationend = () => {
                if (!dialog.value?.attributes.getNamedItem('open')) return
                dialog.value?.classList.remove('hide')
                dialog.value?.close()
                classList?.remove('dialog-open')
                emit('close')
            }
        }

        const closeModalOnBackdrop = () => {
            if (props.noCloseOnBackdrop) return
            closeModal()
        }

        onUnmounted(() => {
            classList.remove('dialog-open')
        })

        return {
            dialog,
            closeModal,
            closeModalOnBackdrop,
        }
    },
})
