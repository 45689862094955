





































import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
    name: 'FooterLayout',
    setup() {
        const footerLinks: { name: string; path: string; target: string }[] = [
            {
                name: 'Impressum',
                path: 'https://www.haendlerbund.de/de/impressum',
                target: 'external',
            },
            {
                name: 'Datenschutz',
                path: 'https://www.haendlerbund.de/de/datenschutzerklaerung',
                target: 'external',
            },
            {
                name: 'Satzung/AGB',
                path: 'https://www.haendlerbund.de/de/satzung-agb',
                target: 'external',
            },
            {
                name: 'händlerbund.de',
                path: 'https://www.haendlerbund.de/de',
                target: 'external',
            },
            {
                name: 'Kontakt',
                path: '/kontakt',
                target: 'internal',
            },
        ]
        const linkClasses = 'footer-link'

        return {
            footerLinks,
            linkClasses,
        }
    },
})
