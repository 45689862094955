// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body.dialog-open{overflow-x:hidden;overflow-y:hidden}.polyfill-dialog{position:fixed;top:50%;transform:translateY(-50%)}@media(min-width:768px){.polyfill-dialog{max-height:calc(100% - 6px - 2em)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#004e87"
};
module.exports = ___CSS_LOADER_EXPORT___;
