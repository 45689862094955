

































































































































































import {
    computed,
    defineComponent,
    ref,
    useRouter,
    watch,
    useRoute,
} from '@nuxtjs/composition-api'
import { useStore } from 'vuex-composition-helpers'
import SearchSuggestion from './SearchSuggestion.vue'
import { useSearch } from '@/hooks/search'
import SimpleCard from '@/components/global/page/SimpleCard.vue'
import PageResultIcon from '@/components/icons/SearchResultPageIcon.vue'
import { RootState } from '@/store'

const SEARCH_MIN_CHARS = 3
const SEARCH_MAX_RESULTS = 6
const SEARCH_DELAY_IN_MS = 200

export default defineComponent({
    name: 'SearchInput',
    components: {
        SimpleCard,
        SearchSuggestion,
        PageResultIcon,
    },
    setup(_props, { emit }) {
        const router = useRouter()
        const route = useRoute()

        const {
            searchResponse,
            searchQueryByRoute,
            searchResult,
            insightsClick,
        } = useSearch()

        const form = ref<HTMLFormElement>()
        const searchInput = ref<HTMLInputElement>()
        const searchSuggestionsWrapper = ref<HTMLDivElement>()
        const searchQuery = ref<string>('')

        if (route.value.path === '/search' && route.value.query.term) {
            searchQuery.value = searchQueryByRoute.value
        }

        const store = useStore<RootState>()
        const recentSearches = computed(() => store.state.recentSearches)

        const recentSearchSuggestions = computed(() => {
            return recentSearches.value.slice(0, 3)
        })

        const focusSearchInput = () => {
            searchInput.value?.focus()
        }

        const clearSearchInput = () => {
            searchQuery.value = ''
            focusSearchInput()
        }

        type SearchSuggestionType = InstanceType<typeof SearchSuggestion>

        const suggestionSearchRef = ref<SearchSuggestionType>()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const suggestionsRef = ref<any[]>([])
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const recentSearchesRef = ref<any[]>([])

        const suggestions = computed<SearchSuggestionType[]>(() => {
            if (!suggestionSearchRef.value) {
                return recentSearchesRef.value
            }
            return [suggestionSearchRef.value, ...suggestionsRef.value]
        })

        const activeSuggestion = ref<SearchSuggestionType>()

        const activeSuggestionIndex = computed(() => {
            return suggestions.value.findIndex(
                (s) => s === activeSuggestion.value
            )
        })

        const focusNextResult = () => {
            if (suggestions.value.length <= 0) {
                activeSuggestion.value = undefined
                return
            }

            if (
                activeSuggestion.value === undefined ||
                activeSuggestion.value ===
                    suggestions.value[suggestions.value.length - 1]
            ) {
                activeSuggestion.value = suggestions.value[0]
                return
            }

            activeSuggestion.value =
                suggestions.value[activeSuggestionIndex.value + 1]
        }

        const focusPrevResult = () => {
            if (suggestions.value.length <= 0) {
                activeSuggestion.value = undefined
                return
            }

            if (
                activeSuggestion.value === undefined ||
                activeSuggestion.value === suggestions.value[0]
            ) {
                activeSuggestion.value =
                    suggestions.value[suggestions.value.length - 1]
                return
            }

            activeSuggestion.value =
                suggestions.value[activeSuggestionIndex.value - 1]
        }

        let algoliaTimeout: ReturnType<typeof setTimeout>

        watch(searchQuery, (query) => {
            activeSuggestion.value = undefined

            if (query.trim().length < SEARCH_MIN_CHARS) {
                return
            }

            clearTimeout(algoliaTimeout)

            algoliaTimeout = setTimeout(async () => {
                searchResponse.value = await searchResult(
                    query,
                    SEARCH_MAX_RESULTS
                )
            }, SEARCH_DELAY_IN_MS)
        })

        const submit = () => {
            form.value?.submit()
        }

        const onSubmit = (objectID?: string, position?: number) => {
            if (
                searchQuery.value.length < SEARCH_MIN_CHARS ||
                suggestions.value.length <= 0
            ) {
                return
            }

            store.commit('ADD_RECENT_SEARCH', searchQuery.value)

            const targetSuggestion =
                activeSuggestion.value || suggestions.value[0]

            if (objectID && position) {
                insightsClick(objectID, position)
            }

            activeSuggestion.value = undefined
            ;(document.activeElement as HTMLElement).blur()

            router.push(targetSuggestion.to)
            emit('search')
        }

        const removeRecentSearches = () => {
            store.commit('CLEAR_RECENT_SEARCHES')
            focusSearchInput()
        }

        const onSubmitRecentSearch = (query: string) => {
            searchQuery.value = query
            onSubmit()
        }

        return {
            SEARCH_MIN_CHARS,
            form,
            searchInput,
            searchSuggestionsWrapper,
            searchQuery,
            clearSearchInput,
            focusSearchInput,
            searchResponse,
            focusNextResult,
            focusPrevResult,
            suggestionSearchRef,
            suggestionsRef,
            activeSuggestion,
            submit,
            onSubmit,
            recentSearchSuggestions,
            removeRecentSearches,
            recentSearchesRef,
            onSubmitRecentSearch,
        }
    },
})
