

























import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        size: {
            type: String,
            default: () => '1em',
        },
        variant: {
            type: String as PropType<'white' | 'black' | 'primary' | 'cta'>,
            default: () => 'black',
        },
    },
})
