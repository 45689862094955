





























































import { PropType } from '@nuxtjs/composition-api'
import Vue from 'vue'

export default Vue.extend({
    name: 'ContactUsSection',
    props: {
        title: {
            type: String,
            default: 'Sie haben Fragen?',
        },
        text: {
            type: String,
            default:
                'Wir sind immer gern für Sie da. Sie haben Fragen zur Händlerbund Mitgliedschaft und unseren Leistungen? Sie benötigen Beratung oder technischen Support? Unser Team kümmert sich schnellstmöglich um Ihr Anliegen.',
        },
        target: {
            type: String,
            default: '/kontakt',
        },
        buttonText: {
            type: String,
            default: 'Jetzt Kontakt aufnehmen',
        },
        imageSrc: {
            type: String as PropType<string | undefined>,
            required: false,
        },
        imageAlt: {
            type: String as PropType<string | undefined>,
            required: false,
        },
        showImage: {
            type: Boolean,
            default: true,
        },
    },
})
