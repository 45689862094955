import VuexPersistence from 'vuex-persist'
import { Context } from '@nuxt/types'
import { RootState } from '@/store'

export default function ({ store }: Context) {
    new VuexPersistence<RootState>({
        storage: window.localStorage,
        reducer: (state): RootState => {
            return {
                ...state,
                viewport: undefined,
                breakpoints: undefined,
                isNavigationSidebarVisible: undefined,
                permissions: undefined,
            }
        },
    }).plugin(store)
}
