











import { defineComponent } from '@nuxtjs/composition-api'

/**
 * Simple variant of a section card without any paddings, media etc.
 * Uses the same classes and css. Just change this, should the section card itself changes too much.
 */
export default defineComponent({
    name: 'SimpleCard',
})
