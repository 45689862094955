import { computed, useContext } from '@nuxtjs/composition-api'
import { TwillPermissions } from '@/plugins/twill'
import { Auth0User, HbMemberPackage } from '@/types/auth0'
import { memberPackageByPermissions } from '@/assets/js/utils'
import { SFSalutation } from '@/types/salesforce'
import { FBSelectOption } from '@/types/forms'

export type Salutation = 'male' | 'female' | 'diverse'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuth = () => {
    const { $auth } = useContext()

    const authHeaders = computed(() => {
        const headers: { 'Content-Type': string; Cookie?: string } = {
            'Content-Type': 'application/json',
        }
        return headers
    })

    const user = computed(() => $auth.user() as unknown as Auth0User)

    const fetchUserprofile = async () => {
        // prevent fetching the userprofile when not logged in e.g. on route /logout
        if (!$auth || !$auth.loggedIn()) {
            return
        }

        await $auth.fetchUser()
    }

    const hbPackageByBatix = computed(() => {
        if (!user.value) return undefined
        const userPackage =
            user.value['http://auth0.haendlerbund.de/fields/package']
        if (userPackage.type === null || userPackage.type === '') return null
        return userPackage
    })

    const batixUuid = computed<string | null | undefined>(() => {
        return user.value?.['http://auth0.haendlerbund.de/fields/batix'].uuid
    })

    const sageUuid = computed<string | null | undefined>(() => {
        return user.value?.['http://auth0.haendlerbund.de/fields/sage'].uuid
    })

    const userAddress = computed(
        () =>
            user.value &&
            user.value['http://auth0.haendlerbund.de/fields/address']
    )

    const userEmail = computed(() => user.value && user.value.email)

    const contactInfo = computed(
        () =>
            user.value &&
            user.value[
                'http://auth0.haendlerbund.de/fields/contact_information'
            ]
    )

    const companyInfo = computed(
        () =>
            user.value &&
            user.value[
                'http://auth0.haendlerbund.de/fields/company_information'
            ]
    )

    const memberId = computed(
        () =>
            (user.value &&
                user.value[
                    'http://auth0.haendlerbund.de/fields/hb_member_id'
                ]) ||
            null
    )

    const permissions = computed(
        () => user.value?.['http://auth0.haendlerbund.de/fields/permission']
    )

    const hasUserPermission = (permission: string): boolean => {
        return !!(
            permissions.value &&
            permissions.value.user.find((perm) => perm === permission)
        )
    }

    const memberPackage = computed<HbMemberPackage | null>(() => {
        if (!permissions.value) {
            return null
        }
        return memberPackageByPermissions(permissions.value)
    })

    const isPermitted = (permissions: TwillPermissions): boolean => {
        const missingPermission = permissions.is_permitted?.find(
            (perm) => !hasUserPermission(perm)
        )

        if (missingPermission) {
            return false
        }

        const foundRestriction = permissions.is_restricted?.find((perm) =>
            hasUserPermission(perm)
        )

        return !foundRestriction
    }

    const findSelectedOption = <T extends string>(
        options: FBSelectOption<T>[],
        selection?: string | null
    ): FBSelectOption<T> | undefined => {
        if (!selection) {
            return undefined
        }

        return options.find((o) =>
            o.value === selection || o.text === selection ? o : undefined
        )
    }

    const salutationOptions: Array<{ value: Salutation; text: string }> = [
        { value: 'male', text: 'Herr' },
        { value: 'female', text: 'Frau' },
        { value: 'diverse', text: 'Divers' },
    ]

    const salutationMapping: Array<{
        sfSalutation: SFSalutation
        cSalutation: string
    }> = [
        { sfSalutation: 'Mx', cSalutation: 'diverse' },
        { sfSalutation: 'Mrs', cSalutation: 'female' },
        { sfSalutation: 'Mr', cSalutation: 'male' },
    ]

    const salutationByAddress = (): string | undefined => {
        if (!userAddress.value?.salutation) {
            return undefined
        }

        const { salutation } = userAddress.value

        return salutation
    }

    const salutationByContactInfo = (): string | undefined => {
        if (!contactInfo.value?.salutation) {
            return undefined
        }

        const { salutation } = contactInfo.value

        return salutation
    }

    const salutationByUserprofile = ():
        | { value: Salutation; text: string }
        | undefined => {
        let salutation = salutationByAddress()
        if (!salutation) {
            const salutationFromContactInfo = salutationByContactInfo()
            salutation = salutationMapping.find((m) => {
                return m.sfSalutation === salutationFromContactInfo ||
                    m.cSalutation === salutationFromContactInfo
                    ? m
                    : undefined
            })?.cSalutation
        }

        return salutationOptions.find((o) => {
            return o.value === salutation || o.text === salutation
                ? o
                : undefined
        })
    }

    // Taken from https://haendlerbund.atlassian.net/wiki/spaces/IT/pages/193560651/Salesforce+API#Account-und-Kontakte
    const legalFormOptions: Array<{ value: string; text: string }> = [
        { value: 'AB', text: 'AB' },
        { value: 'AG', text: 'AG' },
        { value: 'ApS', text: 'ApS' },
        { value: 'B.V.', text: 'B.V.' },
        {
            value: 'Eingetragene Genossenschaft (e.G.)',
            text: 'Eingetragene Genossenschaft (e.G.)',
        },
        {
            value: 'Eingetragener Kaufmann (e.K.)',
            text: 'Eingetragener Kaufmann (e.K.)',
        },
        { value: 'Einzelunternehmen', text: 'Einzelunternehmen' },
        { value: 'Gbr', text: 'Gbr' },
        { value: 'GmbH', text: 'GmbH' },
        { value: 'GmbH & Co. KG', text: 'GmbH & Co. KG' },
        { value: 'KG', text: 'KG' },
        { value: 'Limited (Ltd.)', text: 'Limited (Ltd.)' },
        { value: 'Ltd. & Co. KG', text: 'Ltd. & Co. KG' },
        { value: 'OHG', text: 'OHG' },
        { value: 'SA', text: 'SA' },
        { value: 'SE Societas Europea', text: 'SE Societas Europea' },
        {
            value: 'Sociedad de responsabilidad limitada (S.L.)',
            text: 'Sociedad de responsabilidad limitada (S.L.)',
        },
        {
            value: 'Société à responsabilité limitée (S.á r.l.)',
            text: 'Société à responsabilité limitée (S.á r.l.)',
        },
        { value: 'Stiftung', text: 'Stiftung' },
        { value: 'Stiftung & Co. KG', text: 'Stiftung & Co. KG' },
        { value: 'UG (haftungsbeschränkt)', text: 'UG (haftungsbeschränkt)' },
        {
            value: 'UG (haftungsbeschränkt) & Co. KG',
            text: 'UG (haftungsbeschränkt) & Co. KG',
        },
        { value: 'Verein', text: 'Verein' },
    ]

    const legalFormByCompanyInfo = () => {
        const legalForm = companyInfo.value?.legal_form

        return legalFormOptions.find((o) => {
            return o.value === legalForm || o.text === legalForm ? o : undefined
        })
    }

    return {
        authHeaders,
        batixUuid,
        companyInfo,
        contactInfo,
        fetchUserprofile,
        findSelectedOption,
        hasUserPermission,
        hbPackageByBatix,
        isPermitted,
        legalFormByCompanyInfo,
        legalFormOptions,
        memberId,
        memberPackage,
        permissions,
        sageUuid,
        salutationByAddress,
        salutationByContactInfo,
        salutationByUserprofile,
        salutationOptions,
        user,
        userAddress,
        userEmail,
    }
}
