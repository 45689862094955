import { useContext, ref } from '@nuxtjs/composition-api'
import { ValidationError } from 'express-validation'
import { ApiError } from '@/types/api'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useApi = () => {
    const { $toast } = useContext()

    const isFetching = ref(false)

    const apiFetch = async (
        input: RequestInfo,
        init?: RequestInit,
        options?: {
            timeout?: number
        }
    ): Promise<Response> => {
        const controller = new AbortController()
        const abortTimeout = setTimeout(
            () => controller.abort(),
            options?.timeout || 10000
        )

        isFetching.value = true

        try {
            return await fetch(input, {
                signal: controller.signal,
                ...init,
            })
        } catch (error) {
            const errorMessage = 'Verbindung zum Netzwerk fehlgeschlagen'
            $toast.error(errorMessage)
            throw error
        } finally {
            clearTimeout(abortTimeout)
            isFetching.value = false
        }
    }

    const isApiError = (error: unknown): error is ApiError => {
        return (
            typeof error === 'object' &&
            error !== null &&
            'isApiError' in error === true
        )
    }

    const hasErrorMessage = (
        error: unknown
    ): error is ApiError | Error | ValidationError => {
        return typeof error === 'object' && error !== null && 'message' in error
    }

    return { apiFetch, isFetching, isApiError, hasErrorMessage }
}
