












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ErrorComponent extends Vue {
    @Prop()
    error!: Error
}
