























import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import SectionCardRow from '@/components/global/page/SectionCardRow.vue'
import { useAuth } from '@/hooks/auth'
import { TwillBlock } from '@/plugins/twill'
import SectionCard from '@/components/global/page/SectionCard.vue'

export default defineComponent({
    name: 'TBlockGroup',
    components: {
        SectionCard,
        SectionCardRow,
        TBlock: () => import('@/components/cms/TBlock.vue'),
    },
    props: {
        blocks: {
            type: Array as PropType<TwillBlock[]>,
            required: true,
        },

        isTopLevel: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { isPermitted } = useAuth()

        const isBlockAllowed = (block: TwillBlock): boolean => {
            if (!block) {
                return false
            }

            if (!block.permissions) {
                return true
            }

            return isPermitted(block.permissions)
        }

        type SortedBlockArrayItem = {
            case: 'cards' | 'buttons' | 'other'
            blocks: TwillBlock[]
        }

        const sortedBlockArray = computed<SortedBlockArrayItem[]>(() => {
            const items: SortedBlockArrayItem[] = []

            let lastItem: SortedBlockArrayItem | undefined
            let currentItem: SortedBlockArrayItem | undefined

            /**
             * blocks can be undefined in some test cases
             * therefore we need to check if they exist
             */
            if (!props.blocks) {
                return []
            }

            const allowedBlocks = props.blocks.filter((block) =>
                isBlockAllowed(block)
            )

            allowedBlocks.forEach((currentBlock, currentBlockIndex) => {
                const currentCase = () => {
                    switch (currentBlock.type) {
                        case 'card':
                            return 'cards'
                        case 'button':
                            return 'buttons'
                        default:
                            return 'other'
                    }
                }

                if (!currentItem || lastItem?.case !== currentCase()) {
                    if (currentItem) {
                        items.push(currentItem)
                    }

                    currentItem = {
                        case: currentCase(),
                        blocks: [],
                    }
                }

                currentItem.blocks.push(currentBlock)

                if (currentBlockIndex === allowedBlocks.length - 1) {
                    items.push(currentItem)
                }

                lastItem = { ...currentItem }
            })

            return items
        })

        const hasItemMarginEnd = (item: SortedBlockArrayItem): boolean => {
            if (!props.isTopLevel || item.blocks.length <= 0) {
                return false
            }

            switch (item.blocks[0].type) {
                case 'card':
                case 'picture':
                    return true
                default:
                    return false
            }
        }

        const hasBlockMargin = (block: TwillBlock): boolean => {
            return props.isTopLevel && block.type === 'picture'
        }

        return {
            sortedBlockArray,
            SectionCardRow,
            hasItemMarginEnd,
            hasBlockMargin,
        }
    },
})
