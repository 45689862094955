






































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    inheritAttrs: false,
    emits: {
        close: () => true,
    },
})
