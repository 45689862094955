
















import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
    name: 'LinkButton',

    props: {
        variant: {
            type: String as PropType<'primary' | 'secondary' | 'cta'>,
            default: () => 'secondary',
        },
        block: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String as PropType<'sm' | 'md' | 'lg'>,
            default: () => 'md',
        },
        to: {
            type: String,
            default: undefined,
        },
        href: {
            type: String,
            default: undefined,
        },
        target: {
            type: String,
            default: undefined,
        },
        btnIcon: {
            type: Boolean,
            default: false,
        },
    },
})
