



























































import {
    defineComponent,
    useContext,
    onMounted,
    computed,
    useRoute,
} from '@nuxtjs/composition-api'
import { useActions } from 'vuex-composition-helpers'
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import NavigationSidebar from '@/components/layout/NavigationSidebar/NavigationSidebar.vue'
import ScrollToTop from '@/components/ScrollToTop.vue'
import ContactUsSection from '@/components/global/page/ContactUsSection.vue'
import OverlayMessage from '@/components/global/page/OverlayMessage.vue'
import { useAuth } from '@/hooks/auth'
import { useApi } from '@/hooks/api'

export default defineComponent({
    name: 'DefaultLayout',
    components: {
        Header,
        Footer,
        NavigationSidebar,
        ScrollToTop,
        ContactUsSection,
        OverlayMessage,
    },
    setup() {
        const { store, $twill } = useContext()
        const { initViewport } = useActions(store, ['initViewport'])
        const { fetchUserprofile, user } = useAuth()
        const { apiFetch } = useApi()
        const route = useRoute()

        const clearCacheByGutemine = async () => {
            await apiFetch('/api/gutemine/clearcache', { method: 'delete' })
        }

        clearCacheByGutemine()
        fetchUserprofile()
        setInterval(fetchUserprofile, 20000)

        /**
         * preloading all available pages prevents a bug
         * where too fast route changes could lead to a broken app
         */
        $twill.preloadPages()

        const isAgency = computed(() => {
            return user.value?.['http://auth0.haendlerbund.de/fields/is_agency']
        })

        if (isAgency.value) {
            window.location.href =
                'https://mitglieder.hb-intern.de/login-nr/login-via-auth0.act'
        }

        const isOnContactPage = computed(() => {
            return route.value.path === '/kontakt'
        })

        onMounted(() => {
            initViewport()
        })

        return {
            isOnContactPage,
        }
    },
    head() {
        return {
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
        }
    },
})
