









































































import {
    computed,
    defineComponent,
    onMounted,
    useRoute,
    watch,
} from '@nuxtjs/composition-api'
import { useAuth } from '@/hooks/auth'
import ModalDialog from '@/components/global/page/ModalDialog.vue'
import Button from '@/components/global/page/Button.vue'

export default defineComponent({
    components: {
        ModalDialog,
        Button,
    },
    setup() {
        const { user, permissions, hbPackageByBatix, fetchUserprofile } =
            useAuth()
        const route = useRoute()

        const hasMembership = computed<boolean>(() => {
            return (
                permissions.value?.user.includes(
                    'perm_access_hb-mitgliederbereich'
                ) || false
            )
        })

        const showMembershipModal = computed<boolean>(() => {
            return hasMembership.value && hbPackageByBatix.value === null
        })

        const showBasicDataModal = computed<boolean>(() => {
            return (
                hasMembership.value &&
                !showMembershipModal.value &&
                route.value.path !== '/firmendaten' &&
                route.value.path !== '/kontakt' &&
                !user.value?.[
                    'http://auth0.haendlerbund.de/fields/basic_data_filled'
                ] &&
                user.value?.[
                    'http://auth0.haendlerbund.de/fields/basic_data_last_update'
                ] === null
            )
        })

        // see the rules in the ticket https://haendlerbund.atlassian.net/browse/ITHC-366
        const showBasicDataUpdateModal = computed<boolean>(() => {
            return (
                hasMembership.value &&
                !showMembershipModal.value &&
                route.value.path !== '/firmendaten' &&
                !user.value?.[
                    'http://auth0.haendlerbund.de/fields/basic_data_filled'
                ] &&
                user.value?.[
                    'http://auth0.haendlerbund.de/fields/basic_data_last_update'
                ] !== null
            )
        })

        // Fetching user profile faster than usual (20s) to shut down the overlay once basic data was filled
        const fetchUserprofileWithDelay = () => {
            if (
                hasMembership.value &&
                !showMembershipModal.value &&
                !user.value?.[
                    'http://auth0.haendlerbund.de/fields/basic_data_filled'
                ]
            ) {
                fetchUserprofile()
                window.setTimeout(function () {
                    fetchUserprofileWithDelay()
                }, 1000)
            }
        }

        const showModal = computed<boolean>(() => {
            return (
                showMembershipModal.value ||
                showBasicDataModal.value ||
                showBasicDataUpdateModal.value
            )
        })

        const triggerOverlay = () => {
            if (showModal.value && showBasicDataModal.value) {
                fetchUserprofileWithDelay()
            }
        }

        onMounted(() => {
            triggerOverlay()
        })

        watch(showModal, triggerOverlay)

        return {
            showModal,
            showMembershipModal,
            showBasicDataModal,
            showBasicDataUpdateModal,
        }
    },
})
