































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import LoadingIndicator from './LoadingIndicator.vue'
import { delay } from '@/assets/js/utils'

export default defineComponent({
    components: {
        LoadingIndicator,
    },
    props: {
        variant: {
            type: String as PropType<'primary' | 'secondary' | 'cta'>,
            default: () => 'secondary',
        },
        isLoading: {
            type: Boolean,
            default: () => false,
        },
        savedLabel: {
            type: String,
            default: () => 'Gespeichert',
        },
        size: {
            type: String as PropType<'sm' | 'md' | 'lg'>,
            default: () => 'md',
        },
        isDisabled: {
            type: Boolean,
            default: () => false,
        },
        to: {
            type: String,
            default: undefined,
        },
        href: {
            type: String,
            default: undefined,
        },
        target: {
            type: String,
            default: undefined,
        },
        type: {
            type: String as PropType<'button' | 'submit'>,
            default: undefined,
        },
    },
    setup() {
        const showSuccessIndicator = ref(false)

        const showSuccess = async (value?: boolean) => {
            if (value === undefined) {
                await showSuccess(true)
                await showSuccess(false)
                return
            }
            showSuccessIndicator.value = value
            await delay(1000)
        }

        return { showSuccessIndicator, showSuccess }
    },
})
