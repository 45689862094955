







import { defineComponent, PropType } from '@nuxtjs/composition-api'
import SearchResultPageSvg from '@/assets/icons/search-result-page.svg?inline'

export default defineComponent({
    name: 'SearchResultPageIcon',

    components: { SearchResultPageSvg },

    props: {
        variant: {
            type: String as PropType<'white' | 'primary' | 'gray-400'>,
            default: 'white',
        },
    },
})
