











































import {
    computed,
    defineComponent,
    useRoute,
    watch,
} from '@nuxtjs/composition-api'
import { useStore } from 'vuex-composition-helpers'
import BaseSidebar from '../BaseSidebar.vue'
import MenuItem from './MenuItem.vue'
import { useAuth } from '@/hooks/auth'
import { useNav } from '@/hooks/nav'
import { useCmsMenu } from '@/hooks/twill'
import { RootState } from '@/store'

export default defineComponent({
    name: 'NavigationSidebar',
    components: {
        MenuItem,
        BaseSidebar,
    },
    setup() {
        const store = useStore<RootState>()
        const route = useRoute()
        const isSidebarExpanded = computed(
            () => store.state.isNavigationSidebarVisible
        )

        const closeSidebar = () => {
            store.commit('SET_IS_NAVIGATION_SIDEBAR_VISIBLE', false)
        }

        const { user, permissions } = useAuth()

        const {
            dashboardMenuItem,
            cmsMenuItems,
            accountMenuItems,
            isNavLoaded,
            initNavigation,
            setNavigationRouteByRoute,
        } = useNav()

        const { loadCmsMenuItems } = useCmsMenu()

        initNavigation()

        type LocaleObject = {
            code: string
            name: string
        }

        const getLocaleCode = (input: string | LocaleObject): string => {
            return typeof input === 'string' ? input : input.code
        }
        const getLocaleName = (input: string | LocaleObject): string => {
            return typeof input === 'string' ? input : input.name
        }

        watch(permissions, (newValue, oldValue) => {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                loadCmsMenuItems()
            }
        })

        watch(route, setNavigationRouteByRoute)

        return {
            user,
            dashboardMenuItem,
            cmsMenuItems,
            accountMenuItems,
            isNavLoaded,
            isSidebarExpanded,
            closeSidebar,
            getLocaleCode,
            getLocaleName,
        }
    },
})
