// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".svg-search-result-page .st0{fill:none;stroke:#000;stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.svg-search-result-page .st1{fill:currentColor}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#004e87"
};
module.exports = ___CSS_LOADER_EXPORT___;
