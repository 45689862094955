




























































import {
    defineComponent,
    computed,
    PropType,
    useContext,
} from '@nuxtjs/composition-api'
import { TwillBlockCard, TwillBlockPicture } from '@/plugins/twill'

export default defineComponent({
    name: 'SectionCard',
    props: {
        displayStyle: {
            type: String as PropType<TwillBlockCard['display_style']>,
            dafault: 'regular',
        },

        mediaPosition: {
            type: String as PropType<TwillBlockPicture['media_position']>,
            default: 'right',
        },

        linkTo: {
            type: String,
            default: undefined,
        },

        linkTarget: {
            type: String,
            default: undefined,
        },
        hasMaxImgWidth: {
            type: Boolean,
            default: false,
        },
    },

    setup(props) {
        const { $twill } = useContext()

        const positionRight = computed(() => {
            return props.mediaPosition === 'right'
        })

        const positionLeft = computed(() => {
            return props.mediaPosition === 'left'
        })

        const positionTop = computed(() => {
            return props.mediaPosition === 'top'
        })

        const isCompactCard = computed(() => {
            return props.displayStyle === 'compact'
        })

        const isUltracompactCard = computed(() => {
            return props.displayStyle === 'ultracompact'
        })

        const isRegularCard = computed(() => {
            return props.displayStyle === 'regular'
        })

        const cardPadding = computed(() => {
            if (isCompactCard.value || isUltracompactCard.value) return ''
            else if (isRegularCard.value) return 'p-xl-5'
            return 'p-5'
        })

        const sectionCardContentClasses = computed(() => {
            return [
                'section-card__content d-flex flex-column-reverse h-100',
                {
                    'gap-5':
                        !isCompactCard.value &&
                        (positionRight.value || positionLeft.value),
                    'flex-2xl-column': positionTop.value,
                    'flex-xl-row-reverse':
                        positionRight.value && isRegularCard.value,
                    'flex-2xl-row-reverse':
                        positionRight.value && isCompactCard.value,
                    'flex-2xl-row':
                        positionLeft.value && !isUltracompactCard.value,
                },
                cardPadding.value,
            ]
        })

        const isExternalLink = computed(() =>
            props.linkTo ? $twill.isExternal(props.linkTo) : false
        )

        const wrapperTag = computed<string>(() => {
            if (!props.linkTo) {
                return 'div'
            }

            return isExternalLink.value ? 'a' : 'nuxt-link'
        })

        return {
            isRegularCard,
            isCompactCard,
            isUltracompactCard,
            sectionCardContentClasses,
            isExternalLink,
            wrapperTag,
        }
    },
})
