import {
    useContext,
    useStore,
    computed,
    ComputedRef,
} from '@nuxtjs/composition-api'
import { useAuth } from '@/hooks/auth'
import { RootState } from '@/store'
import { TwillMenuItem, TwillPage } from '@/plugins/twill'

export const usePage = (
    slug: string
): {
    page: ComputedRef<TwillPage | undefined>
    loadPage: () => Promise<TwillPage | undefined>
} => {
    const { $twill } = useContext()
    const store = useStore<RootState>()

    const page = computed(() => store.state.pages[slug])

    const loadPage = () => $twill.page(slug)

    return { page, loadPage }
}

export const useCmsMenu = (): {
    cmsMenuItems: ComputedRef<TwillMenuItem[] | undefined>
    customerMenuItems: ComputedRef<TwillMenuItem[] | undefined>
    loadCmsMenuItems: () => Promise<TwillMenuItem[]>
    loadCustomerMenuItems: () => Promise<TwillMenuItem[]>
} => {
    const { $twill } = useContext()
    const { isPermitted } = useAuth()
    const store = useStore<RootState>()

    const filterMenuItems = (items: TwillMenuItem[] | undefined) => {
        if (!items) {
            return items
        }

        return items.filter((item) => {
            if (!item.permissions) {
                return true
            }

            return isPermitted(item.permissions)
        })
    }

    const cmsMenuItems = computed(() => {
        return filterMenuItems(store.state.cmsMenuItems)
    })

    const customerMenuItems = computed(() => {
        return filterMenuItems(store.state.customerMenuItems)
    })

    const loadCmsMenuItems = () => $twill.cmsMenuItems()
    const loadCustomerMenuItems = () => $twill.customerMenuItems()

    return {
        cmsMenuItems,
        customerMenuItems,
        loadCmsMenuItems,
        loadCustomerMenuItems,
    }
}
