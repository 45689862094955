import Vue from 'vue'
import { Store } from 'vuex'
import axios, { CancelTokenSource } from 'axios'
import { Context } from '@nuxt/types'
import {
    TwillHbNewsletter,
    TwillLocale,
    TwillMenuItem,
    TwillPage,
    TwillPlugin,
    TwillResponse,
} from './twill'
import TPage from '@/components/cms/TPage.vue'
import { RootState } from '@/store'

Vue.component('TPage', TPage)

let pageCancelTokenSource: CancelTokenSource | undefined

export default (
    { app, store: anyStore, error: nuxtError }: Context,
    inject: (name: string, plugin: unknown) => void
): TwillPlugin => {
    const menuItems = async (menuType: string, storeType: string) => {
        const response = await axios.get<{ data: TwillMenuItem[] }>(
            `/api/cms/${menuType}`
        )

        store.commit(storeType, response.data.data)
        return response.data.data
    }

    const store = anyStore as Store<RootState>
    const $twill: TwillPlugin | null = {
        preloadPages: async () => {
            const response = await axios.get<{ data: TwillPage[] }>(
                `/api/cms/page`
            )

            const pages: { slug: string; page: TwillPage }[] =
                response.data.data.map((page) => {
                    return {
                        slug: page.resource_codes[0].resource_code,
                        page,
                    }
                })

            store.commit('ADD_PAGES', pages)
        },
        page: async (slug) => {
            try {
                if (pageCancelTokenSource) {
                    pageCancelTokenSource.cancel()
                }

                pageCancelTokenSource = axios.CancelToken.source()

                const response = await axios.get<{ data: TwillPage }>(
                    `/api/cms/page/by-code/${slug}`,
                    { cancelToken: pageCancelTokenSource.token }
                )
                store.commit('ADD_PAGE', { slug, page: response.data.data })
                return response.data.data
            } catch (error) {
                if (axios.isCancel(error)) {
                    return undefined
                }

                if (axios.isAxiosError(error) && error.response) {
                    nuxtError({ statusCode: error.response.status })
                }
            }
        },
        isPageLoaded: (slug) => {
            return store.state.pages[slug] !== undefined
        },
        cmsMenuItems: async () => {
            try {
                return await menuItems('side-menu', 'SET_CMS_MENU_ITEMS')
            } catch (error) {
                throw new Error(
                    'Unable to load main navigation items (top left menu)'
                )
            }
        },
        customerMenuItems: async () => {
            try {
                return await menuItems(
                    'customer-menu',
                    'SET_CUSTOMER_MENU_ITEMS'
                )
            } catch (error) {
                throw new Error(
                    'Unable to load customer navigation items (bottom left menu)'
                )
            }
        },
        hbnewsletters: async (currentPage) => {
            try {
                const response = await axios.get<
                    TwillResponse<TwillHbNewsletter[]>
                >(
                    `/api/cms/hbnewsletter?page=${currentPage}&sort_by=date_sent&sort_order=desc`
                )
                return response.data
            } catch (error) {
                throw new Error('Unable to load newsletters')
            }
        },
        t: (localizedString, fallback?) => {
            const locale = app.i18n.locale as TwillLocale
            const fallbackLocale = app.i18n.fallbackLocale as TwillLocale
            return (
                localizedString[locale] ||
                localizedString[fallbackLocale] ||
                fallback
            )
        },
        tImage: (localizedImage) => {
            const locale = app.i18n.locale as TwillLocale
            const fallbackLocale = app.i18n.fallbackLocale as TwillLocale
            return (
                localizedImage.find((image) => image.locale === locale) ??
                localizedImage.find((image) => image.locale === fallbackLocale)
            )
        },
        isExternal(url) {
            return !url.startsWith('/')
        },
    }
    inject('twill', $twill)
    return $twill
}

declare module 'vue/types/vue' {
    interface Vue {
        $twill: TwillPlugin
    }
}
