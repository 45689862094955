// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal.mobile-nav-modal .modal-header{background-color:#004e87;color:#fff;z-index:500;padding:.5rem .5rem .5rem .75rem}.modal.mobile-nav-modal .modal-header .modal-title{font-weight:400}.modal.mobile-nav-modal .modal-header .btn-icon{height:inherit;font-size:1.5rem}.modal.mobile-nav-modal .search-input{border-radius:0;border:none;border-bottom:1px solid #e4e4e4}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#004e87"
};
module.exports = ___CSS_LOADER_EXPORT___;
