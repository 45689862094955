// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sidebar{display:flex;flex-direction:column;top:0;height:100vh;width:320px}.sidebar__outer{position:-webkit-sticky;position:sticky;top:0;min-height:100vh;background:#2b2f36}.sidebar__header{display:flex;align-items:center;position:relative}.sidebar__body{flex-grow:1;overflow:auto;padding-bottom:3rem}.sidebar__close-button{position:absolute;width:60px;height:60px;right:-60px;font-size:1.5rem}@media(max-width:991.98px){.sidebar__outer{position:fixed;z-index:250;box-shadow:0 0 2rem rgba(0,0,0,.3)}.sidebar__backdrop{position:fixed;left:0;top:0;width:100vw;height:100vh;background:rgba(0,0,0,.6);z-index:249}.sidebar-fade-enter-active,.sidebar-fade-leave-active,.sidebar-slide-enter-active,.sidebar-slide-leave-active{transition:all .3s ease-out}.sidebar-slide-enter,.sidebar-slide-leave-to{transform:translateX(-320px)}.sidebar-fade-enter,.sidebar-fade-leave-to{opacity:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#004e87"
};
module.exports = ___CSS_LOADER_EXPORT___;
