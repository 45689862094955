var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"form",staticClass:"h-100",attrs:{"role":"search"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"search-input-wrapper d-flex align-items-center h-100"},[_c('button',{staticClass:"search-scope-button",on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-search text-primary"})]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],ref:"searchInput",staticClass:"form-control search-input",attrs:{"type":"text","placeholder":"Seiten durchsuchen"},domProps:{"value":(_vm.searchQuery)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.focusNextResult.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.focusPrevResult.apply(null, arguments)}],"input":function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value}}}),_vm._v(" "),(_vm.searchQuery.length > 0)?_c('button',{staticClass:"search-clear-button",on:{"click":_vm.clearSearchInput}},[_c('i',{staticClass:"fa fa-times text-primary"})]):_vm._e(),_vm._v(" "),(
                _vm.recentSearchSuggestions.length > 0 ||
                _vm.searchQuery.trim().length >= _vm.SEARCH_MIN_CHARS
            )?_c('div',{ref:"searchSuggestionsWrapper",staticClass:"search-suggestions",class:{
                'recent-searches': _vm.recentSearchSuggestions.length > 0,
            }},[_c('SimpleCard',{staticClass:"pt-4 pb-3"},[(
                        _vm.recentSearchSuggestions.length > 0 &&
                        _vm.searchQuery.trim().length < _vm.SEARCH_MIN_CHARS
                    )?[_c('div',{staticClass:"\n                            d-flex\n                            justify-content-between\n                            align-items-center\n                        "},[_c('div',{staticClass:"pl-3"},[_vm._v("Zuletzt gesucht")]),_vm._v(" "),_c('button',{staticClass:"recent-searches-clear-button",on:{"click":_vm.removeRecentSearches}},[_vm._v("\n                            letzte Suchanfragen löschen\n                        ")])]),_vm._v(" "),_vm._l((_vm.recentSearchSuggestions),function(recentSearch,index){return _c('SearchSuggestion',{key:("recent-search-" + index),ref:"recentSearchesRef",refInFor:true,class:{
                            active:
                                _vm.recentSearchesRef[index] ===
                                _vm.activeSuggestion,
                        },attrs:{"to":("/search?term=" + recentSearch)},nativeOn:{"mouseover":function($event){_vm.activeSuggestion = _vm.recentSearchesRef[index]},"click":function($event){$event.preventDefault();return _vm.onSubmitRecentSearch(recentSearch)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"fa fa-search"})]},proxy:true},{key:"right",fn:function(){return [_c('i',{staticClass:"fa fa-chevron-right ml-3 text-sm"})]},proxy:true}],null,true)},[_vm._v(" "),_c('strong',[_vm._v(_vm._s(recentSearch))])])})]:_vm._e(),_vm._v(" "),(_vm.searchQuery.trim().length >= _vm.SEARCH_MIN_CHARS)?[_c('SearchSuggestion',{ref:"suggestionSearchRef",staticClass:"border-bottom",class:{
                            active:
                                _vm.suggestionSearchRef === _vm.activeSuggestion,
                        },attrs:{"to":("/search?term=" + (_vm.searchQuery.trim()))},nativeOn:{"mouseover":function($event){_vm.activeSuggestion = _vm.suggestionSearchRef},"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"fa fa-search"})]},proxy:true},{key:"right",fn:function(){return [_c('span',{staticClass:"text-sm text-gray-400"},[_vm._v("\n                                Alle Ergebnisse\n                            ")]),_vm._v(" "),_c('i',{staticClass:"fa fa-chevron-right ml-3 text-sm"})]},proxy:true}],null,false,2339238173)},[_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.searchQuery))])]),_vm._v(" "),(_vm.searchResponse)?[_c('p',{staticClass:"px-3 pt-2 pb-0 m-0"},[_vm._v("Seiten")]),_vm._v(" "),_vm._l((_vm.searchResponse.hits),function(result,index){return _c('SearchSuggestion',{key:("search-result-" + index),ref:"suggestionsRef",refInFor:true,class:{
                                active:
                                    _vm.suggestionsRef[index] ===
                                    _vm.activeSuggestion,
                            },attrs:{"to":("/" + (result.slug))},nativeOn:{"mouseover":function($event){_vm.activeSuggestion = _vm.suggestionsRef[index]},"click":function($event){$event.preventDefault();return _vm.onSubmit(result.objectID, index + 1)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('PageResultIcon')]},proxy:true}],null,true)},[_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(
                                    result._highlightResult &&
                                    result._highlightResult.title
                                        ? result._highlightResult.title
                                              .value
                                        : result.title
                                )}})])})]:_vm._e()]:_vm._e()],2)],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"search-backdrop"})])}
var staticRenderFns = []

export { render, staticRenderFns }