





















































import { defineComponent, useContext } from '@nuxtjs/composition-api'
import { useMutations, useState } from 'vuex-composition-helpers'
import Logo from '@/components/layout/Logo.vue'
import MobileSearch from '@/components/header/navbar-tools/MobileSearch.vue'
import User from '@/components/header/navbar-tools/User.vue'
import Contact from '@/components/header/navbar-tools/Contact.vue'
import DesktopSearch from '@/components/header/navbar-tools/DesktopSearch.vue'

export default defineComponent({
    name: 'Header',
    components: {
        Logo,
        MobileSearch,
        DesktopSearch,
        User,
        Contact,
    },
    setup() {
        const { store } = useContext()
        const { SET_IS_NAVIGATION_SIDEBAR_VISIBLE } = useMutations(store, [
            'SET_IS_NAVIGATION_SIDEBAR_VISIBLE',
        ])
        const { isNavigationSidebarVisible } = useState(store, [
            'isNavigationSidebarVisible',
        ])

        return {
            SET_IS_NAVIGATION_SIDEBAR_VISIBLE,
            isNavigationSidebarVisible,
        }
    },
})
