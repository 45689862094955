import { ref, computed, useRoute } from '@nuxtjs/composition-api'
import algoliasearch from 'algoliasearch/lite'
import insightsClient from 'search-insights'
import { useAuth } from './auth'
import type { Unwrap } from '@/types/helper'

insightsClient('init', {
    appId: process.env.ALGOLIA_APP_ID,
    apiKey: process.env.ALGOLIA_PUBLIC,
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSearch = () => {
    const route = useRoute()
    const { isPermitted } = useAuth()

    const algoliaClient = algoliasearch(
        process.env.ALGOLIA_APP_ID ?? '',
        process.env.ALGOLIA_PUBLIC ?? '',
        {
            headers: {
                'X-Algolia-UserToken': 'user',
            },
        }
    )

    const searchIndex = algoliaClient.initIndex(
        `${process.env.ALGOLIA_INDEX_PAGES}_de`
    )

    const search = (...params: Parameters<typeof searchIndex.search>) => {
        return searchIndex.search<{
            content: string
            headings: string
            slug: string
            title: string
            // eslint-disable-next-line camelcase
            permissions_permitted: string[]
            // eslint-disable-next-line camelcase
            permissions_restricted: string[]
        }>(...params)
    }

    const searchResponse = ref<Unwrap<ReturnType<typeof search>>>()

    const searchQueryByRoute = computed<string>(() => {
        const { term } = route.value.query
        if (Array.isArray(term)) {
            return (term.filter((t) => t !== null) as string[]).join(' ')
        }
        return term
    })

    const searchResult = async (query: string, maxSearches: number) => {
        const result = await search(query.trim(), {
            clickAnalytics: true,
            hitsPerPage: maxSearches,
            highlightPreTag: '<strong>',
            highlightPostTag: '</strong>',
        })
        result.hits = result.hits.filter((hit) => {
            return isPermitted({
                is_restricted: hit.permissions_restricted,
                is_permitted: hit.permissions_permitted,
            })
        })
        return result
    }

    const insightsClick = (objectID: string, position: number) => {
        if (!searchResponse.value?.queryID) return
        insightsClient('clickedObjectIDsAfterSearch', {
            userToken: 'user',
            eventName: 'Selected Suggestion',
            index: `${process.env.ALGOLIA_INDEX_PAGES}_de`,
            queryID: searchResponse.value?.queryID,
            objectIDs: [objectID],
            positions: [position],
        })
    }

    return {
        search,
        searchResponse,
        searchQueryByRoute,
        searchResult,
        insightsClick,
    }
}
