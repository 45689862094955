var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.wrapperTag,{tag:"component",staticClass:"section-card",attrs:{"href":_vm.linkTo,"to":!_vm.isExternalLink ? _vm.linkTo : undefined,"target":_vm.linkTarget}},[_c('div',{staticClass:"section-card__bg bg-white"},[(_vm.isUltracompactCard || _vm.isCompactCard)?_c('div',{class:_vm.sectionCardContentClasses},[_c('div',{staticClass:"section-card__content__media",class:[
                    ("section-card__" + _vm.displayStyle + "__media__" + _vm.mediaPosition),
                    {
                        'justify-content-center':
                            _vm.hasMaxImgWidth && _vm.displayStyle === 'regular',
                    } ]},[_vm._t("media")],2),_vm._v(" "),_c('div',{staticClass:"section-card__content__main invisible"},[_vm._t("default")],2)]):_vm._e()]),_vm._v(" "),_c('section',{class:_vm.sectionCardContentClasses},[(_vm.$slots['media'])?_c('div',{staticClass:"section-card__content__media",class:[
                ("section-card__" + _vm.displayStyle + "__media__" + _vm.mediaPosition),
                {
                    invisible: _vm.isUltracompactCard || _vm.isCompactCard,
                    'justify-content-center align-self-center align-self-xl-start img-centered':
                        _vm.hasMaxImgWidth && _vm.displayStyle === 'regular',
                } ]},[_vm._t("media")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"section-card__content__main margin-block-group",class:{
                'p-3 p-xl-5': _vm.isUltracompactCard || _vm.isCompactCard,
                'p-3 p-xl-0': _vm.isRegularCard,
            }},[_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }