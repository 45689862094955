





















import {
    defineComponent,
    ref,
    onBeforeMount,
    onBeforeUnmount,
    useStore,
} from '@nuxtjs/composition-api'
import { RootState } from '@/store'
export default defineComponent({
    setup() {
        const initialPosition = ref(0)
        const isVisible = ref<boolean>(false)
        const store = useStore<RootState>()

        const scroll = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }

        const handleScroll = () => {
            isVisible.value =
                window.scrollY <= initialPosition.value &&
                window.scrollY >= 100 &&
                store.getters.isViewportDown('md')

            initialPosition.value = window.scrollY
        }

        onBeforeMount(() => {
            window.addEventListener('scroll', handleScroll)
        })

        onBeforeUnmount(() => {
            window.removeEventListener('scroll', handleScroll)
        })

        return {
            isVisible,
            scroll,
        }
    },
})
