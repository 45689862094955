// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".search-suggestion{display:flex;width:100%;align-items:center;color:#2b2f36}.search-suggestion-icon{width:48px;padding:.75rem;text-align:center;color:#aeaeae}.search-suggestion-right{padding:.75rem;text-align:right}.search-suggestion-content{flex:1}.search-suggestion:hover{text-decoration:none;color:inherit}.search-suggestion.active{background-color:#e6f1fa;color:#0374c7}.search-suggestion.active .search-suggestion-icon{color:#0374c7}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#004e87"
};
module.exports = ___CSS_LOADER_EXPORT___;
