























import { defineComponent, ref, watch, useRoute } from '@nuxtjs/composition-api'
import { useClickOutside } from '@/hooks/useClickOutside'

export default defineComponent({
    props: {
        listClasses: {
            type: String,
            default: '',
        },
    },

    setup() {
        const route = useRoute()
        const elementRef = ref<HTMLLIElement>()
        const isExpanded = ref(false)

        const onHandleClick = () => {
            isExpanded.value = !isExpanded.value
        }

        useClickOutside(elementRef, () => {
            isExpanded.value = false
        })

        // on route change close expanded dropdown in header
        watch(
            () => route.value.path,
            () => {
                isExpanded.value = false
            }
        )

        return {
            elementRef,
            isExpanded,
            onHandleClick,
            route,
        }
    },
})
