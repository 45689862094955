import { onMounted, onUnmounted, unref } from '@nuxtjs/composition-api'
import { NullableMaybeRef } from '@/types/helper'

interface OnClickOutsideOptions {
    /**
     * array of HtmlElements or vue template refs we want to ignore
     */
    ignoreDomElements?: NullableMaybeRef<HTMLElement>[]
    /**
     * addEventListener capture
     *
     * @default false
     * */
    capture?: boolean
}

// damn safari doesn't have requestIdleCallback
// ref: https://caniuse.com/requestidlecallback
const hasRequestIdleCallback = typeof window.requestIdleCallback !== 'undefined'

/**
 * @summary
 * test we have an ignored HTML Element inside the event.composedPath
 *
 * @description
 * we can define optional 'ignored HTML Elements'
 * if any of the ignored HTML Elements is inside the event.composedPath, the callback will *not* be fired
 *
 * @param {NullableMaybeRef<HTMLElement>[]} arrayOfDomElements - array of HtmlElements or vue template refs
 * @param {PointerEvent | UIEvent} event - event from eventListener
 * @returns boolean
 */
export const hasIgnoredDomElement = (
    arrayOfDomElements: NullableMaybeRef<HTMLElement>[],
    event: PointerEvent | UIEvent
): boolean =>
    arrayOfDomElements.some((domElement) => {
        if (domElement === null) return false
        const element = unref(domElement)
        return (
            element &&
            (event.target === element || event.composedPath().includes(element))
        )
    })

/**
 * @summary maybe fire CallbackHandler
 *
 * @description
 * fire Callback if 'target' dom-path is outside of event dom-path
 *
 * if any optional given Parameter 'ignoreDomElements' is set, the callback will *not* firing when 'event.composedPath'
 * includes the dom-path of the ignoring Element
 *
 * @param {NullableMaybeRef<HTMLElement>} target - target Element as vue template ref
 * @param {Function} handler - callback function
 * @param {Object} [options]
 * @param {NullableMaybeRef<HTMLElement>[]} [options.ignoreDomElements] - array of HtmlElements or vue template refs
 * @param {PointerEvent | UIEvent} event - event from eventListener
 */
const maybeCallHandler = ({
    event,
    handler,
    options,
    target,
}: {
    event: PointerEvent | UIEvent
    handler: (evt: PointerEvent | UIEvent) => void
    options: OnClickOutsideOptions
    target: NullableMaybeRef<HTMLElement>
}): void => {
    const { ignoreDomElements } = options

    const el = unref(target)

    const isIgnored =
        !el || el === event.target || event.composedPath().includes(el)

    if (isIgnored) return

    if (!(ignoreDomElements && ignoreDomElements.length > 0))
        return handler(event)

    if (hasIgnoredDomElement(ignoreDomElements, event)) return

    handler(event)
}

/**
 * @summary Listen for clicks outside a given HTML Element
 *
 * @description
 * fires a given handler when click outside a given HTML Element
 *
 * if any optional given Parameter 'ignoreDomElements' is set, the callback will *not* firing when 'event.composedPath'
 * includes the dom-path of the ignoring Element
 *
 * @param {NullableMaybeRef<HTMLElement>} target - target Element as vue template ref
 * @param {Function} handler - callback function
 * @param {OnClickOutsideOptions} [options]
 */

export const useClickOutside = (
    target: NullableMaybeRef<HTMLElement>,
    handler: (evt: PointerEvent | UIEvent) => void,
    options: OnClickOutsideOptions = {}
) => {
    // if we are on SSR, exit
    if (process?.server) return

    const { capture = false } = options
    const listener = (event: PointerEvent | UIEvent) => {
        const callback = maybeCallHandler({
            event,
            handler,
            options,
            target,
        })

        return hasRequestIdleCallback
            ? window.requestIdleCallback(() => callback)
            : window.requestAnimationFrame(() => callback)
    }

    onMounted(() => {
        window.addEventListener('pointerdown', listener, {
            capture,
            passive: true,
        })
        window.addEventListener('focusin', listener, {
            capture,
        })
    })

    onUnmounted(() => {
        // ts doesn't allow 'passive' option for 'removeEventlistener'
        // ref:https://github.com/microsoft/TypeScript/issues/32912
        window.removeEventListener('pointerdown', listener, {
            capture,
        })
        window.removeEventListener('focusin', listener, {
            capture,
        })
    })
}
