// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".scroll-to-top-button{position:fixed;display:block;right:16px;bottom:16px;width:3.75rem;height:3.75rem;box-shadow:0 0 12px 0 rgba(0,0,0,.15)}.scroll-to-top-button.slide-fade-enter-active{-webkit-animation:move-fade-in-without-chat .2s ease;animation:move-fade-in-without-chat .2s ease}.scroll-to-top-button.slide-fade-leave-active{-webkit-animation:move-fade-out-without-chat .2s ease;animation:move-fade-out-without-chat .2s ease}html.hs-messages-mobile .scroll-to-top-button{transform:translateY(-4.5rem)}html.hs-messages-mobile .scroll-to-top-button.slide-fade-enter-active{-webkit-animation:move-fade-in-with-chat .2s ease;animation:move-fade-in-with-chat .2s ease}html.hs-messages-mobile .scroll-to-top-button.slide-fade-leave-active{-webkit-animation:move-fade-out-with-chat .2s ease;animation:move-fade-out-with-chat .2s ease}@-webkit-keyframes move-fade-in-with-chat{0%{opacity:0;transform:translateY(-2.5rem)}to{opacity:1;transform:translateY(-4.5rem)}}@keyframes move-fade-in-with-chat{0%{opacity:0;transform:translateY(-2.5rem)}to{opacity:1;transform:translateY(-4.5rem)}}@-webkit-keyframes move-fade-out-with-chat{0%{opacity:1;transform:translateY(-4.5rem)}to{opacity:0;transform:translateY(-2.5rem)}}@keyframes move-fade-out-with-chat{0%{opacity:1;transform:translateY(-4.5rem)}to{opacity:0;transform:translateY(-2.5rem)}}@-webkit-keyframes move-fade-in-without-chat{0%{opacity:0;transform:translateY(2rem)}to{opacity:1;transform:translateY(0)}}@keyframes move-fade-in-without-chat{0%{opacity:0;transform:translateY(2rem)}to{opacity:1;transform:translateY(0)}}@-webkit-keyframes move-fade-out-without-chat{0%{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(2rem)}}@keyframes move-fade-out-without-chat{0%{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(2rem)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#004e87"
};
module.exports = ___CSS_LOADER_EXPORT___;
