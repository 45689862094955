import { extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

extend('required', {
    ...required,
    message: 'Das Feld ist ein Pflichtfeld',
})

extend('email', {
    ...email,
    message:
        'Bitte geben Sie eine korrekte E-Mail-Adresse im Format email@musteremail.com ein',
})
