// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#navigation-sidebar button.close{position:absolute;top:6px;opacity:1;right:0}@media(min-width:380px){#navigation-sidebar button.close{right:-60px}}#navigation-sidebar .menu-item-link{color:#ddd}#navigation-sidebar .menu-item-link:hover{text-decoration:none}#navigation-sidebar button.menu-item-link{background-color:transparent;border:none;text-align:left}#navigation-sidebar .menu-item-icon{width:2em}#navigation-sidebar .menu-item-toggle-button{width:3rem;background-color:transparent}#navigation-sidebar .menu-item-lvl-1{border-bottom:1px solid #595959}#navigation-sidebar .menu-item-lvl-1>.menu-item-link-wrapper .menu-item-toggle-button{background-color:#424852}#navigation-sidebar .menu-item-lvl-1>.menu-item-link-wrapper>.menu-item-link{min-height:3rem}#navigation-sidebar .menu-item-lvl-1>.menu-item-link-wrapper>.menu-item-link.nuxt-link-active{background-color:#004e87}#navigation-sidebar .menu-item-lvl-1>.menu-item-link-wrapper>.menu-item-link.nuxt-link-active+.menu-item-toggle-button{background-color:#005da1}#navigation-sidebar .menu-item-lvl-2>.menu-item-link-wrapper>.menu-item-link,#navigation-sidebar .menu-item-lvl-3>.menu-item-link-wrapper>.menu-item-link{min-height:2.5rem}#navigation-sidebar .menu-item-lvl-2>.menu-item-link-wrapper>.menu-item-link.nuxt-link-active,#navigation-sidebar .menu-item-lvl-2>.menu-item-link-wrapper>.menu-item-link.nuxt-link-active+.menu-item-toggle-button,#navigation-sidebar .menu-item-lvl-3>.menu-item-link-wrapper>.menu-item-link.nuxt-link-active,#navigation-sidebar .menu-item-lvl-3>.menu-item-link-wrapper>.menu-item-link.nuxt-link-active+.menu-item-toggle-button{background-color:hsla(0,0%,100%,.15)}#navigation-sidebar .menu-item-lvl-2>.menu-item-link-wrapper~ul .menu-item-link,#navigation-sidebar .menu-item-lvl-3>.menu-item-link-wrapper~ul .menu-item-link{background-color:hsla(0,0%,100%,.05)}#navigation-sidebar .menu-item-lvl-3 .menu-item-title{margin-left:1.5em;font-size:.9em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#004e87"
};
module.exports = ___CSS_LOADER_EXPORT___;
