var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-item list-unstyled w-100",class:[
        ("menu-item menu-item-lvl-" + _vm.level),
        {
            'menu-item-active': _vm.isActive,
            'menu-item-exact-active': _vm.isExactActive,
        } ]},[_c('div',{staticClass:"menu-item-link-wrapper d-flex"},[(_vm.item.slug)?_c('nuxt-link',{attrs:{"to":_vm.localePath(("/" + (_vm.$twill.t(_vm.item.slug, '')))),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var navigate = ref.navigate;
return [_c('a',{staticClass:"\n                    menu-item-link\n                    w-100\n                    px-3\n                    d-flex\n                    align-items-center\n                    justify-content-between\n                ",class:[
                    _vm.menuItemClasses,
                    {
                        'nuxt-link-active': _vm.isActive,
                        'nuxt-link-exact-active': _vm.isExactActive,
                    } ],attrs:{"href":href,"title":_vm.$twill.t(_vm.item.title, '')},on:{"click":navigate}},[_c('i',{staticClass:"menu-item-icon d-block text-left",class:_vm.item.icon}),_vm._v(" "),_c('span',{staticClass:"menu-item-title flex-grow-1 w-100"},[_vm._v("\n                    "+_vm._s(_vm.$twill.t(_vm.item.title, ''))+"\n                ")])])]}}],null,false,2200907923)}):_vm._e(),_vm._v(" "),(_vm.item.external_url)?_c('a',{class:_vm.menuItemClasses,attrs:{"href":_vm.$twill.t(_vm.item.external_url),"title":_vm.$twill.t(_vm.item.title, '')}},[_c('i',{staticClass:"menu-item-icon d-block text-left",class:_vm.item.icon}),_vm._v(" "),(_vm.level === 3)?_c('div',{staticClass:"menu-item-indicator"}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"menu-item-title flex-grow-1 w-100"},[_vm._v("\n                "+_vm._s(_vm.$twill.t(_vm.item.title, ''))+"\n            ")]),_vm._v(" "),_c('i',{staticClass:"fas fa-external-link-alt d-block",staticStyle:{"font-size":"0.7em"}})]):_vm._e(),_vm._v(" "),(!_vm.item.slug && !_vm.item.external_url && _vm.subItems.length > 0)?_c('button',{class:[
                _vm.menuItemClasses,
                {
                    'nuxt-link-active': _vm.isActive,
                    'nuxt-link-exact-active': _vm.isExactActive,
                } ],attrs:{"title":_vm.$twill.t(_vm.item.title, '')},on:{"click":function($event){$event.preventDefault();return _vm.handleItemButtonClick.apply(null, arguments)}}},[_c('i',{staticClass:"menu-item-icon d-block text-left",class:_vm.item.icon}),_vm._v(" "),_c('span',{staticClass:"menu-item-title flex-grow-1 w-100"},[_vm._v("\n                "+_vm._s(_vm.$twill.t(_vm.item.title, ''))+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.subItems.length > 0)?_c('button',{staticClass:"\n                menu-item-toggle-button\n                border-0\n                text-white\n                d-flex\n                justify-content-center\n                align-items-center\n            ",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.isCollapsed = !_vm.isCollapsed}}},[_c('i',{staticClass:"menu-item-icon-right fas",class:{
                    'fa-angle-up': !_vm.isCollapsed,
                    'fa-angle-down': _vm.isCollapsed,
                }})]):_vm._e()],1),_vm._v(" "),(_vm.subItems.length > 0)?_c('CollapseTransition',{attrs:{"dimension":"height","name":"collapse"}},[(!_vm.isCollapsed)?_c('ul',{staticClass:"p-0 m-0"},_vm._l((_vm.subItems),function(subItem,index){return _c('MenuItem',{key:("item-" + index),attrs:{"item":subItem,"level":_vm.level + 1,"parentPath":_vm.path}})}),1):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }