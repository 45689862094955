import { ValidationError } from 'express-validation'
import { Auth0User, HbMemberPackage } from '@/types/auth0'
import { DataLayer } from '@/types/tracking'
import { FBSelectOption, CountryCode } from '@/types/forms'

export const removeAllWhitespaces = (input: string): string => {
    return input.replace(/\s/g, '')
}

export const delay = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export const formatPrice = (
    price: number,
    numberFormatOptions: Intl.NumberFormatOptions = {},
    locale = 'de-DE'
): string => {
    return Intl.NumberFormat(locale, {
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: 'EUR',
        ...numberFormatOptions,
    }).format(price)
}

export const formatPhoneNumber = (phoneNumber: string): string => {
    return phoneNumber.replace(/\s/g, '')
}

export const memberPackageByPermissions = (
    permissions: Auth0User['http://auth0.haendlerbund.de/fields/permission']
): HbMemberPackage | null => {
    const hasUserPermission = (permission: string): boolean =>
        !!permissions.user.find((perm) => perm === permission)

    if (hasUserPermission('perm_buy_hb-member-professional-only-products')) {
        return { id: 'professional', label: 'PROFESSIONAL-Mitgliedschaft' }
    }

    if (hasUserPermission('perm_buy_hb-member-unlimited-only-products')) {
        return { id: 'unlimited', label: 'UNLIMITED-Mitgliedschaft' }
    }

    if (hasUserPermission('perm_buy_hb-member-premium-only-products')) {
        return { id: 'premium', label: 'PREMIUM-Mitgliedschaft' }
    }

    if (hasUserPermission('perm_buy_hb-member-basic-only-products')) {
        return { id: 'basic', label: 'BASIC-Mitgliedschaft' }
    }

    if (hasUserPermission('perm_buy_hb-member-local-only-products')) {
        return { id: 'local', label: 'LOCAL Paket' }
    }

    if (hasUserPermission('perm_buy_hb-member-essential-only-products')) {
        return { id: 'essential', label: 'Grundmitgliedschaft' }
    }

    return null
}

/**
 * Check if validation error contains error for the given field label
 * @param error
 * @param fieldLabel
 */
export const fieldHasErrors = (
    error: ValidationError,
    fieldLabel: string
): boolean => {
    const filteredByLabel = error.details.body?.filter(
        // @ts-ignore
        (errItem) => errItem.context?.label === fieldLabel
    )
    return (filteredByLabel && filteredByLabel.length > 0) || false
}

/**
 * Get all validation error messages for a given field label
 *
 * @param error
 * @param fieldLabel
 * @param language
 */
export const getErrorMessagesForField = (
    error: ValidationError,
    fieldLabel: string,
    language?: 'de'
): string[] => {
    const errMessageTranslations: Record<
        string,
        {
            key: string
            translations: Record<'de', string>
        }[]
    > = {
        email: [
            {
                key: 'must be a valid email',
                translations: {
                    de: '"Email" muss eine valide E-Mail-Adresse sein',
                },
            },
            {
                key: 'ending with "example.com"',
                translations: {
                    de: '"Email" darf nicht auf "example.com" enden',
                },
            },
            {
                key: 'is required',
                translations: {
                    de: '"Email" muss angegeben werden',
                },
            },
        ],
    }
    return (
        error.details.body
            // @ts-ignore
            ?.filter((errItem) => errItem.context?.label === fieldLabel)
            // @ts-ignore
            .map((o) => {
                let message = o.message

                if (language && errMessageTranslations[fieldLabel]) {
                    message =
                        errMessageTranslations[fieldLabel].find((tl) =>
                            message.includes(tl.key)
                        )?.translations.de || message
                }

                return message
            }) || []
    )
}

export const addGmtDataLayer = (data: DataLayer): void => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(data)
}

/*
    Example for disabled option
    { value: 'DE', text: 'Deutschland', $isDisabled: true }
*/
export const countryOptions: FBSelectOption<CountryCode>[] = [
    { value: 'DE', text: 'Deutschland' },
    { value: 'AT', text: 'Österreich' },
    { value: 'CH', text: 'Schweiz' },
    { value: 'BE', text: 'Belgien' },
    { value: 'BG', text: 'Bulgarien' },
    { value: 'CN', text: 'China' },
    { value: 'DK', text: 'Dänemark' },
    { value: 'EE', text: 'Estland' },
    { value: 'FI', text: 'Finnland' },
    { value: 'FR', text: 'Frankreich' },
    { value: 'GR', text: 'Griechenland' },
    { value: 'GB', text: 'Großbritannien' },
    { value: 'IE', text: 'Irland' },
    { value: 'IS', text: 'Island' },
    { value: 'IT', text: 'Italien' },
    { value: 'LV', text: 'Lettland' },
    { value: 'LU', text: 'Luxemburg' },
    { value: 'MT', text: 'Malta' },
    { value: 'NL', text: 'Niederlande' },
    { value: 'NO', text: 'Norwegen' },
    { value: 'PL', text: 'Polen' },
    { value: 'PT', text: 'Portugal' },
    { value: 'SE', text: 'Schweden' },
    { value: 'ES', text: 'Spanien' },
    { value: 'TH', text: 'Thailand' },
    { value: 'CZ', text: 'Tschechien' },
    { value: 'TR', text: 'Türkei' },
    { value: 'HU', text: 'Ungarn' },
    { value: 'US', text: 'USA' },
    { value: 'CY', text: 'Zypern' },
]

export const hasKey = <O>(obj: O, key: PropertyKey): key is keyof O => {
    return key in obj
}
