import { Context } from '@nuxt/types'
import { Message } from 'gelf-pro'
import { AUTH_COOKIE_NAME } from '../auth.config'
import { Logger, MessageExtra } from '@/plugins/logging/logger'
import { useApi } from '@/hooks/api'

export default function (
    { $cookies }: Context,
    inject: (name: string, plugin: unknown) => void
): void {
    const sendLog = async (
        msg: Message,
        level: string,
        data: MessageExtra = {},
        stacktrace?: string
    ) => {
        data.origin = 'client'

        const authJwt = $cookies.get(AUTH_COOKIE_NAME)
        const { apiFetch } = useApi()

        const response = await apiFetch('/api/utilities/log', {
            method: 'post',
            headers: {
                Authorization: `Bearer ${authJwt}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                message: msg,
                stacktrace,
                level,
                additional: data,
            }),
        })

        if (!response.ok) {
            const data = await response.json()
            // eslint-disable-next-line no-console
            console.warn(
                `unable to create log entry | status: ${response.status} | ${data}`
            )
        }
    }

    const functions = {
        info: (msg: Message, data?: MessageExtra): void => {
            sendLog(msg, 'info', data)
        },
        error: (err: Error, data?: MessageExtra): void => {
            sendLog(err.message, 'error', data, err.stack)
        },
    }

    inject('logger', functions)
}

declare module 'vue/types/vue' {
    interface Vue {
        $logger: Logger
    }
}
