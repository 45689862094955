


































































































































import {
    computed,
    defineComponent,
    PropType,
    ref,
    watch,
} from '@nuxtjs/composition-api'
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'
import { TwillMenuItem } from '@/plugins/twill'
import { useAuth } from '@/hooks/auth'
import { useNav } from '@/hooks/nav'

export default defineComponent({
    name: 'MenuItem',
    components: { CollapseTransition },
    props: {
        item: {
            type: Object as PropType<TwillMenuItem>,
            required: true,
        },
        level: {
            type: Number as PropType<1 | 2 | 3>,
            default: () => 1,
        },
        parentPath: {
            type: String as PropType<string | null>,
            default: () => null,
        },
    },
    setup(props) {
        const { isPermitted } = useAuth()

        const { navigationRoute, setNavigationRoute } = useNav()

        const menuItemClasses =
            'menu-item-link w-100 px-3 d-flex align-items-center justify-content-between'

        const path = computed<string | null>(() => {
            return props.parentPath
                ? `${props.parentPath}/${props.item.id}`
                : `${props.item.id}`
        })

        const isActive = computed<boolean>(() => {
            if (!navigationRoute.value || !path.value) {
                return false
            }

            const pathParts = path.value.split('/')
            const routeParts = navigationRoute.value.split('/')

            return !pathParts.find((pathPart, i) => pathPart !== routeParts[i])
        })

        const isExactActive = computed<boolean>(() => {
            if (!navigationRoute.value) {
                return false
            }
            return navigationRoute.value === path.value
        })

        const subItems = computed<TwillMenuItem[]>(() => {
            if (!props.item.children) {
                return []
            }

            return props.item.children.filter((item) => {
                return item.permissions ? isPermitted(item.permissions) : true
            })
        })

        const isCollapsed = ref<boolean>(!isActive.value)

        const handleItemButtonClick = () => {
            if (!path.value) {
                return
            }

            setNavigationRoute(path.value)
        }

        watch(navigationRoute, () => {
            isCollapsed.value = !isActive.value
        })

        return {
            isCollapsed,
            path,
            subItems,
            menuItemClasses,
            isActive,
            isExactActive,
            handleItemButtonClick,
        }
    },
})
