import { Context } from '@nuxt/types'
import { StatusCodes } from 'http-status-codes'
import axios from 'axios'
import { Auth } from './auth'
import { Userprofile } from '@/types/auth0'

export default function (
    { store, route, redirect }: Context,
    inject: (name: string, plugin: unknown) => void
): void {
    const functions: Auth = {
        user: () => store.state.userprofile || null,
        loggedIn: () => !!store.state.userprofile,
        login: () => {
            if (route.path !== '/logout') {
                localStorage.setItem('post_login_intended_url', route.path)
            }

            redirect(
                StatusCodes.MOVED_TEMPORARILY,
                `${process.env.BASE_URL}/api/auth/login`
            )
        },
        logout: () => {
            store.commit('CLEAR_USERPROFILE')
            localStorage.clear()

            redirect(
                StatusCodes.MOVED_TEMPORARILY,
                `${process.env.BASE_URL}/api/auth/logout`
            )
        },
        async fetchUser(): Promise<Userprofile> {
            const response = await axios.get<Userprofile>(
                '/api/gutemine/userprofile'
            )

            if (JSON.stringify(this.user()) !== JSON.stringify(response.data)) {
                store.commit('SET_USERPROFILE', {
                    ...this.user(),
                    ...response.data,
                })
            }

            return response.data
        },
    }

    inject('auth', functions)
}
