import { Context } from '@nuxt/types'

const skipRoutes = ['/api/auth/login', '/api/auth/logout']

export default async function ({ redirect, route, store, $auth }: Context) {
    if (skipRoutes.includes(route.path)) {
        return
    }

    // logout action has to be performed inside a middleware because redirects don't work proper inside setup
    // functions of vue components e.g. inside logout.vue
    if (route.path === '/logout') {
        if ($auth.loggedIn()) {
            $auth.logout()
        }
        return
    }

    const res = await fetch('/api/auth/verify-session')
    const body = await res.json()

    if (!body.valid || body.shouldReLogin) {
        $auth.login()
        return
    }

    const postLoginTarget =
        localStorage.getItem('post_login_intended_url') || null
    if (postLoginTarget && !skipRoutes.includes(postLoginTarget)) {
        localStorage.removeItem('post_login_intended_url')

        if (postLoginTarget !== route.path) {
            redirect(postLoginTarget)
            return
        }
    }

    if (!store.state.userprofile) {
        const res2 = await fetch('/api/auth/profile')
        const data = await res2.json()
        store.commit('SET_USERPROFILE', data)
    }
}
