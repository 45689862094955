import type { GetterTree, MutationTree, ActionTree } from 'vuex'
import type { ViewportType, Breakpoints, Breakpoint } from '@/types/breakpoints'
import type { TwillMenuItem, TwillPage } from '@/plugins/twill'
import type { SDCustomerIssue, SDResponse } from '@/types/service-desk'
import type { Auth0Permissions, Userprofile } from '@/types/auth0'
import type { VLCartQuestionAnswer, VLProduct } from '@/types/verleihnix'
import { CSubscription } from '@/types/customer'

// disabled, as we infer return type as RootState
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const state = () => ({
    viewport: undefined as ViewportType | undefined,
    breakpoints: undefined as Breakpoints | undefined,
    isNavigationSidebarVisible: undefined as boolean | undefined,
    navigationRoute: undefined as string | undefined,
    cmsMenuItems: undefined as TwillMenuItem[] | undefined,
    customerMenuItems: undefined as TwillMenuItem[] | undefined,
    pages: {} as Record<string, TwillPage>,
    serviceDeskAccountBlockingRequests: undefined as
        | SDResponse<SDCustomerIssue>
        | undefined,
    serviceDeskWarningLetterRequests: undefined as
        | SDResponse<SDCustomerIssue>
        | undefined,
    permissions: undefined as Auth0Permissions | undefined,
    products: [] as VLProduct[],
    cartQuestionAnswers: [] as {
        salesforceId: string
        answer: VLCartQuestionAnswer
    }[],
    recentSearches: [] as string[],
    subscriptions: undefined as CSubscription[] | undefined,
    userprofile: undefined as Userprofile | undefined,
})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
    isViewportUp:
        (state) =>
        (viewportToCheck: ViewportType): boolean | undefined => {
            if (!state.breakpoints || !state.viewport) {
                return undefined
            }
            const viewPorts = Object.keys(state.breakpoints) as ViewportType[]
            return (
                viewPorts.indexOf(viewportToCheck) <=
                viewPorts.indexOf(state.viewport)
            )
        },
    isViewportDown:
        (state) =>
        (viewportToCheck: ViewportType): boolean | undefined => {
            if (!state.breakpoints || !state.viewport) {
                return undefined
            }
            const viewPorts = Object.keys(state.breakpoints) as ViewportType[]
            return (
                viewPorts.indexOf(viewportToCheck) >
                viewPorts.indexOf(state.viewport)
            )
        },
}

export const mutations: MutationTree<RootState> = {
    SET_BREAKPOINTS: (state) => {
        const documentElement = window.getComputedStyle(
            document.documentElement
        )
        const regexFilterNumbers = '/[^d]/g'
        const breakpointGap = 0.02
        const xsBreakpoint = parseFloat(
            documentElement
                .getPropertyValue('--breakpoint-xs')
                .replace(regexFilterNumbers, '')
        )
        const smBreakpoint = parseFloat(
            documentElement
                .getPropertyValue('--breakpoint-sm')
                .replace(regexFilterNumbers, '')
        )
        const mdBreakpoint = parseFloat(
            documentElement
                .getPropertyValue('--breakpoint-md')
                .replace(regexFilterNumbers, '')
        )
        const lgBreakpoint = parseFloat(
            documentElement
                .getPropertyValue('--breakpoint-lg')
                .replace(regexFilterNumbers, '')
        )
        const xlBreakpoint = parseFloat(
            documentElement
                .getPropertyValue('--breakpoint-xl')
                .replace(regexFilterNumbers, '')
        )
        state.breakpoints = {
            xs: {
                minWidth: xsBreakpoint,
                maxWidth: smBreakpoint - breakpointGap,
                get mediaQuery() {
                    return `(max-width: ${this.maxWidth}px)`
                },
            },
            sm: {
                minWidth: smBreakpoint,
                maxWidth: mdBreakpoint - breakpointGap,
                get mediaQuery() {
                    return `(min-width: ${this.minWidth}px) and (max-width: ${this.maxWidth}px)`
                },
            },
            md: {
                minWidth: mdBreakpoint,
                maxWidth: lgBreakpoint - breakpointGap,
                get mediaQuery() {
                    return `(min-width: ${this.minWidth}px) and (max-width: ${this.maxWidth}px)`
                },
            },
            lg: {
                minWidth: lgBreakpoint,
                maxWidth: xlBreakpoint - breakpointGap,
                get mediaQuery() {
                    return `(min-width: ${this.minWidth}px) and (max-width: ${this.maxWidth}px)`
                },
            },
            xl: {
                minWidth: xlBreakpoint,
                maxWidth: Infinity,
                get mediaQuery() {
                    return `(min-width: ${this.minWidth}px)`
                },
            },
        }
    },
    SET_VIEWPORT: (state) => {
        if (state.breakpoints) {
            const { xs, sm, md, lg, xl } = state.breakpoints
            if (window.matchMedia(xs.mediaQuery).matches) {
                state.viewport = 'xs'
                return
            }

            if (window.matchMedia(sm.mediaQuery).matches) {
                state.viewport = 'sm'
                return
            }

            if (window.matchMedia(md.mediaQuery).matches) {
                state.viewport = 'md'
                return
            }

            if (window.matchMedia(lg.mediaQuery).matches) {
                state.viewport = 'lg'
                return
            }

            if (window.matchMedia(xl.mediaQuery).matches) {
                state.viewport = 'xl'
            }
        } else {
            throw new Error(
                "'SET_VIEWPORT' mutation failed, because breakpoints weren't set. Run 'SET_BREAKPOINTS' before."
            )
        }
    },
    SET_IS_NAVIGATION_SIDEBAR_VISIBLE: (state, isVisible: boolean) => {
        state.isNavigationSidebarVisible = isVisible
    },
    SET_NAVIGATION_ROUTE: (state, route: string) => {
        state.navigationRoute = route
    },
    SET_CMS_MENU_ITEMS: (state, cmsMenuItems: TwillMenuItem[]) => {
        state.cmsMenuItems = cmsMenuItems
    },
    SET_CUSTOMER_MENU_ITEMS: (state, cmsMenuItems: TwillMenuItem[]) => {
        state.customerMenuItems = cmsMenuItems
    },
    ADD_PAGE: (state, payload: { slug: string; page: TwillPage }) => {
        state.pages = {
            ...state.pages,
            [payload.slug]: payload.page,
        }
    },
    ADD_PAGES: (state, payload: { slug: string; page: TwillPage }[]) => {
        const newPages = { ...state.pages }
        for (const item of payload) {
            /**
             * pages loaded in bulk are missing the blocks
             * so we only them if they weren't there before
             */
            if (!newPages[item.slug]) {
                newPages[item.slug] = item.page
            }
        }
        state.pages = newPages
    },
    SERVICE_DESK_SET_ACCOUNT_BLOCKING_REQUESTS: (
        state,
        response: SDResponse<SDCustomerIssue>
    ) => {
        state.serviceDeskAccountBlockingRequests = response
    },
    SERVICE_DESK_SET_WARNING_LETTER_REQUESTS: (
        state,
        response: SDResponse<SDCustomerIssue>
    ) => {
        state.serviceDeskWarningLetterRequests = response
    },
    SET_PERMISSIONS: (state, permissions: Auth0Permissions) => {
        state.permissions = permissions
    },
    ADD_PRODUCT: (state, product: VLProduct) => {
        state.products = [
            ...state.products.filter((p) => p.sku !== product.sku),
            product,
        ]
    },
    ADD_CART_QUESTION_ANSWER: (
        state,
        payload: {
            salesforceId: string
            cartQuestionAnswer: VLCartQuestionAnswer
        }
    ) => {
        state.cartQuestionAnswers = [
            ...state.cartQuestionAnswers.filter(
                (cqa) =>
                    cqa.salesforceId !== payload.salesforceId &&
                    cqa.answer.cart_question_id !==
                        payload.cartQuestionAnswer.cart_question_id
            ),
            {
                salesforceId: payload.salesforceId,
                answer: payload.cartQuestionAnswer,
            },
        ]
    },
    ADD_RECENT_SEARCH: (state, recentSearch: string) => {
        const hasRecentSearch = state.recentSearches.includes(recentSearch)
        if (hasRecentSearch) {
            const recentSearchIndex = state.recentSearches.indexOf(recentSearch)
            state.recentSearches.splice(recentSearchIndex, 1)
        }
        state.recentSearches.unshift(recentSearch)
    },
    CLEAR_RECENT_SEARCHES: (state) => {
        state.recentSearches = []
    },
    SET_SUBSCRIPTIONS: (state, subscriptions: CSubscription[]) => {
        state.subscriptions = subscriptions
    },
    SET_USERPROFILE: (state, userprofile: Userprofile) => {
        state.userprofile = userprofile
    },
    CLEAR_USERPROFILE: (state) => {
        state.userprofile = undefined
    },
}

export const actions: ActionTree<RootState, RootState> = {
    initViewport({ commit, state, getters }) {
        commit('SET_BREAKPOINTS')
        commit('SET_VIEWPORT')
        if (state.breakpoints) {
            if (getters.isViewportUp('lg')) {
                commit('SET_IS_NAVIGATION_SIDEBAR_VISIBLE', true)
            } else {
                commit('SET_IS_NAVIGATION_SIDEBAR_VISIBLE', false)
            }

            const breakpointValues: Breakpoint[] = Object.values(
                state.breakpoints
            )
            for (const breakpoint of breakpointValues) {
                window.matchMedia(breakpoint.mediaQuery).addListener(() => {
                    commit('SET_VIEWPORT')
                    if (getters.isViewportUp('lg')) {
                        commit('SET_IS_NAVIGATION_SIDEBAR_VISIBLE', true)
                    } else {
                        commit('SET_IS_NAVIGATION_SIDEBAR_VISIBLE', false)
                    }
                })
            }
        }
    },
}
