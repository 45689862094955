// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".search-input-wrapper{position:relative}.search-input-wrapper>button{position:absolute;line-height:1.5;padding:.375rem .75rem;border:1px solid transparent;background:transparent;z-index:401}.search-input-wrapper>button.search-clear-button{right:0}.search-input-wrapper .search-input{padding-left:2.4rem;padding-right:2.4rem;z-index:400}.search-input-wrapper .search-suggestions{position:absolute;left:0;top:2.1em;width:100%;z-index:399}.search-input-wrapper .search-suggestions.recent-searches button.recent-searches-clear-button{padding:.375rem .75rem;background:transparent;color:#004e87;border:0}@media(min-width:992px){.search-input-wrapper{max-width:500px}}form[role=search] .search-suggestions{display:none}form[role=search] .search-backdrop{visibility:hidden}form[role=search]:focus .search-suggestions,form[role=search][focus-within] .search-suggestions{display:block}form[role=search]:focus-within .search-suggestions,form[role=search]:focus .search-suggestions{display:block}form[role=search]:focus .search-backdrop,form[role=search][focus-within] .search-backdrop{visibility:visible;opacity:.7}form[role=search]:focus-within .search-backdrop,form[role=search]:focus .search-backdrop{visibility:visible;opacity:.7}.search-backdrop{position:fixed;top:0;bottom:0;left:0;right:0;background-color:#000;opacity:0;z-index:398;transition:opacity .3s ease-in-out}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#004e87"
};
module.exports = ___CSS_LOADER_EXPORT___;
