// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loading-indicator{-webkit-animation:spinwithease 1s ease-in-out infinite;animation:spinwithease 1s ease-in-out infinite}.loading-indicator.variant-black path{fill:#000}.loading-indicator.variant-white path{fill:#fff}.loading-indicator.variant-primary path{fill:#004e87}.loading-indicator.variant-cta path{fill:#f07e26}@-webkit-keyframes spinwithease{0%{transform:rotate(0deg)}to{transform:rotate(2turn)}}@keyframes spinwithease{0%{transform:rotate(0deg)}to{transform:rotate(2turn)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#004e87"
};
module.exports = ___CSS_LOADER_EXPORT___;
