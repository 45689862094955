






import SearchInput from './SearchInput.vue'

export default {
    components: {
        SearchInput,
    },
}
