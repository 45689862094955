















import {
    computed,
    defineComponent,
    useContext,
    useMeta,
    watch,
} from '@nuxtjs/composition-api'
// Blocks are prefixed with "T" for "Twill" because there are some
// reserved words like "Text" which we cannot use for component names
import TBlockGroup from '@/components/cms/TBlockGroup.vue'
import { useAuth } from '@/hooks/auth'
import { usePage } from '@/hooks/twill'
import { TwillPageMeta } from '@/plugins/twill'
import SectionCard from '@/components/global/page/SectionCard.vue'

export default defineComponent({
    name: 'TPage',
    components: {
        SectionCard,
        TBlockGroup,
    },
    props: {
        slug: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const { $twill } = useContext()
        const { page } = usePage(props.slug)
        const meta = useMeta()

        const { isPermitted } = useAuth()

        const isPageAllowed = computed(() => {
            if (!page.value?.permissions) {
                return true
            }

            return isPermitted(page.value.permissions)
        })

        const updatePageMeta = () => {
            const getPageTitle = (): string | undefined => {
                if (page.value?.meta.meta_title) {
                    const tValue = $twill.t(page.value.meta.meta_title)

                    if (tValue) {
                        return `${tValue} | Händlerbund Login-Bereich`
                    }
                }

                return undefined
            }

            const setTwillMeta = (property: keyof TwillPageMeta): void => {
                if (page.value?.meta[property]) {
                    const tValue = $twill.t(page.value?.meta[property])

                    if (tValue) {
                        const metaName = property.replace('meta_', '')

                        meta.meta.value = [
                            ...meta.meta.value,
                            {
                                hid: metaName,
                                name: metaName,
                                content: tValue,
                            },
                        ]
                    }
                }
            }

            meta.title.value = getPageTitle()
            setTwillMeta('meta_description')
            setTwillMeta('meta_keywords')
        }

        watch(isPageAllowed, () => {
            if (!isPageAllowed) {
                throw new Error('Dieser Bereich ist für Sie nicht gestattet')
            }
        })

        watch(page, updatePageMeta)

        return { page, isPageAllowed }
    },

    head: {
        // head needs to be set to be changed later
    },
})
