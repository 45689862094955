import {
    computed,
    ref,
    useContext,
    useRoute,
    useStore,
} from '@nuxtjs/composition-api'
import { useCmsMenu } from './twill'
import { TwillLocale, TwillMenuItem } from '@/plugins/twill'
import { RootState } from '@/store'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNav = () => {
    const { state, commit } = useStore<RootState>()
    const route = useRoute()
    const { i18n } = useContext()

    const navigationRoute = computed(() => state.navigationRoute)

    const isNavLoaded = ref<boolean>(false)

    const setNavigationRoute = (newRoute: string) => {
        commit('SET_NAVIGATION_ROUTE', newRoute)
    }

    const dashboardMenuItem: TwillMenuItem = {
        id: 'dashboard',
        title: { de: 'Dashboard' },
        slug: { de: 'dashboard' },
        icon: 'fa fa-home',
    }

    const logoutMenuItem: TwillMenuItem = {
        id: 'logout',
        title: { de: 'Logout' },
        slug: { de: 'logout' },
    }

    const {
        cmsMenuItems,
        customerMenuItems,
        loadCmsMenuItems,
        loadCustomerMenuItems,
    } = useCmsMenu()

    const accountMenuItems = computed<TwillMenuItem[]>(() => {
        let children = customerMenuItems.value
        if (!children) {
            children = []
        }

        // @ts-ignore
        children.push(logoutMenuItem)

        // icons are not wanted in customer menu
        children = children.map((child) => {
            // copy of object is required because otherwise we would change child for the whole app
            const tmp = { ...child }
            tmp.icon = undefined
            return tmp
        })

        return [
            {
                id: 'account',
                title: { de: 'Kundenkonto' },
                slug: { de: 'kundenkonto' },
                icon: 'fa fa-user',
                children,
            },
        ]
    })

    const profileMenuItems = computed<TwillMenuItem[]>(() => {
        let items = customerMenuItems.value
        if (!items) {
            items = []
        }

        // @ts-ignore
        items.push(logoutMenuItem)

        return items
    })

    type SlugListItem = { slug: string; path: string; item: TwillMenuItem }

    const slugList = computed<SlugListItem[]>(() => {
        const list: SlugListItem[] = []

        const addSlugList = (
            items: TwillMenuItem[],
            parentPath?: string
        ): void => {
            for (const item of items) {
                const slug: string | undefined =
                    item.slug?.[i18n.locale as TwillLocale] || undefined

                const path = parentPath
                    ? `${parentPath}/${item.id}`
                    : `${item.id}`

                if (slug) {
                    list.push({ slug, path, item })
                }

                if (item.children) {
                    addSlugList(item.children, path)
                }
            }
        }

        addSlugList([
            dashboardMenuItem,
            ...accountMenuItems.value,
            ...(cmsMenuItems.value || []),
        ])

        return list
    })

    const setNavigationRouteByRoute = () => {
        const slug = route.value.path.replace('/', '')
        const item = slugList.value.find((i) => i.slug === slug)

        if (
            !navigationRoute.value ||
            !item ||
            navigationRoute.value !== item.path
        ) {
            setNavigationRoute(item?.path || 'dashboard')
        }
    }

    const initNavigation = async () => {
        await loadCmsMenuItems()
        await loadCustomerMenuItems()

        setNavigationRouteByRoute()

        isNavLoaded.value = true
    }

    return {
        navigationRoute,
        setNavigationRoute,
        setNavigationRouteByRoute,
        initNavigation,
        isNavLoaded,
        dashboardMenuItem,
        cmsMenuItems,
        // please notice that the account menu is not named "customer menu" because
        // the customer menu is only part of the account menu
        accountMenuItems,
        profileMenuItems,
    }
}
