export const NoConsent = () => import('../../components/global/NoConsent.vue' /* webpackChunkName: "components/no-consent" */).then(c => wrapFunctional(c.default || c))
export const FBCheckbox = () => import('../../components/global/forms/FBCheckbox.vue' /* webpackChunkName: "components/f-b-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FBCountrySelect = () => import('../../components/global/forms/FBCountrySelect.vue' /* webpackChunkName: "components/f-b-country-select" */).then(c => wrapFunctional(c.default || c))
export const FBFileUploader = () => import('../../components/global/forms/FBFileUploader.vue' /* webpackChunkName: "components/f-b-file-uploader" */).then(c => wrapFunctional(c.default || c))
export const FBFormGroup = () => import('../../components/global/forms/FBFormGroup.vue' /* webpackChunkName: "components/f-b-form-group" */).then(c => wrapFunctional(c.default || c))
export const FBInput = () => import('../../components/global/forms/FBInput.vue' /* webpackChunkName: "components/f-b-input" */).then(c => wrapFunctional(c.default || c))
export const FBPhoneNumberInput = () => import('../../components/global/forms/FBPhoneNumberInput.vue' /* webpackChunkName: "components/f-b-phone-number-input" */).then(c => wrapFunctional(c.default || c))
export const FBSelect = () => import('../../components/global/forms/FBSelect.vue' /* webpackChunkName: "components/f-b-select" */).then(c => wrapFunctional(c.default || c))
export const FBShopSelect = () => import('../../components/global/forms/FBShopSelect.vue' /* webpackChunkName: "components/f-b-shop-select" */).then(c => wrapFunctional(c.default || c))
export const FBTextarea = () => import('../../components/global/forms/FBTextarea.vue' /* webpackChunkName: "components/f-b-textarea" */).then(c => wrapFunctional(c.default || c))
export const FormBuilder = () => import('../../components/global/forms/FormBuilder.vue' /* webpackChunkName: "components/form-builder" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/global/page/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const CircularProgressBar = () => import('../../components/global/page/CircularProgressBar.vue' /* webpackChunkName: "components/circular-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const CollapseIcon = () => import('../../components/global/page/CollapseIcon.vue' /* webpackChunkName: "components/collapse-icon" */).then(c => wrapFunctional(c.default || c))
export const ContactUsSection = () => import('../../components/global/page/ContactUsSection.vue' /* webpackChunkName: "components/contact-us-section" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/global/page/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const LinkButton = () => import('../../components/global/page/LinkButton.vue' /* webpackChunkName: "components/link-button" */).then(c => wrapFunctional(c.default || c))
export const LoadingIndicator = () => import('../../components/global/page/LoadingIndicator.vue' /* webpackChunkName: "components/loading-indicator" */).then(c => wrapFunctional(c.default || c))
export const ModalDialog = () => import('../../components/global/page/ModalDialog.vue' /* webpackChunkName: "components/modal-dialog" */).then(c => wrapFunctional(c.default || c))
export const OverlayMessage = () => import('../../components/global/page/OverlayMessage.vue' /* webpackChunkName: "components/overlay-message" */).then(c => wrapFunctional(c.default || c))
export const ProgressSteps = () => import('../../components/global/page/ProgressSteps.vue' /* webpackChunkName: "components/progress-steps" */).then(c => wrapFunctional(c.default || c))
export const SectionCard = () => import('../../components/global/page/SectionCard.vue' /* webpackChunkName: "components/section-card" */).then(c => wrapFunctional(c.default || c))
export const SectionCardCol = () => import('../../components/global/page/SectionCardCol.vue' /* webpackChunkName: "components/section-card-col" */).then(c => wrapFunctional(c.default || c))
export const SectionCardRow = () => import('../../components/global/page/SectionCardRow.vue' /* webpackChunkName: "components/section-card-row" */).then(c => wrapFunctional(c.default || c))
export const SimpleCard = () => import('../../components/global/page/SimpleCard.vue' /* webpackChunkName: "components/simple-card" */).then(c => wrapFunctional(c.default || c))
export const CartQuestionGroup = () => import('../../components/global/page/product/cart-questions/CartQuestionGroup.vue' /* webpackChunkName: "components/cart-question-group" */).then(c => wrapFunctional(c.default || c))
export const CartQuestionWrapper = () => import('../../components/global/page/product/cart-questions/CartQuestionWrapper.vue' /* webpackChunkName: "components/cart-question-wrapper" */).then(c => wrapFunctional(c.default || c))
export const BatixShopList = () => import('../../components/global/page/product/cart-questions/types/BatixShopList.vue' /* webpackChunkName: "components/batix-shop-list" */).then(c => wrapFunctional(c.default || c))
export const ScrollToTop = () => import('../../components/ScrollToTop.vue' /* webpackChunkName: "components/scroll-to-top" */).then(c => wrapFunctional(c.default || c))
export const AccountCancellationForm = () => import('../../components/account/CancellationForm.vue' /* webpackChunkName: "components/account-cancellation-form" */).then(c => wrapFunctional(c.default || c))
export const AccountInvoice = () => import('../../components/account/Invoice.vue' /* webpackChunkName: "components/account-invoice" */).then(c => wrapFunctional(c.default || c))
export const AccountOrderList = () => import('../../components/account/OrderList.vue' /* webpackChunkName: "components/account-order-list" */).then(c => wrapFunctional(c.default || c))
export const AccountSubscriptionInvoices = () => import('../../components/account/SubscriptionInvoices.vue' /* webpackChunkName: "components/account-subscription-invoices" */).then(c => wrapFunctional(c.default || c))
export const AccountSubscriptionItem = () => import('../../components/account/SubscriptionItem.vue' /* webpackChunkName: "components/account-subscription-item" */).then(c => wrapFunctional(c.default || c))
export const BatixAgency = () => import('../../components/batix/Agency.vue' /* webpackChunkName: "components/batix-agency" */).then(c => wrapFunctional(c.default || c))
export const BatixIframe = () => import('../../components/batix/BatixIframe.vue' /* webpackChunkName: "components/batix-iframe" */).then(c => wrapFunctional(c.default || c))
export const BatixChangeHistory = () => import('../../components/batix/ChangeHistory.vue' /* webpackChunkName: "components/batix-change-history" */).then(c => wrapFunctional(c.default || c))
export const BatixKaeufersiegel = () => import('../../components/batix/Kaeufersiegel.vue' /* webpackChunkName: "components/batix-kaeufersiegel" */).then(c => wrapFunctional(c.default || c))
export const BatixLegalTexts = () => import('../../components/batix/LegalTexts.vue' /* webpackChunkName: "components/batix-legal-texts" */).then(c => wrapFunctional(c.default || c))
export const BatixMemberLogo = () => import('../../components/batix/MemberLogo.vue' /* webpackChunkName: "components/batix-member-logo" */).then(c => wrapFunctional(c.default || c))
export const BatixShopData = () => import('../../components/batix/ShopData.vue' /* webpackChunkName: "components/batix-shop-data" */).then(c => wrapFunctional(c.default || c))
export const BatixShopDataLocalMembership = () => import('../../components/batix/ShopDataLocalMembership.vue' /* webpackChunkName: "components/batix-shop-data-local-membership" */).then(c => wrapFunctional(c.default || c))
export const BatixShopDepthTest = () => import('../../components/batix/ShopDepthTest.vue' /* webpackChunkName: "components/batix-shop-depth-test" */).then(c => wrapFunctional(c.default || c))
export const BatixShopDepthTestOverview = () => import('../../components/batix/ShopDepthTestOverview.vue' /* webpackChunkName: "components/batix-shop-depth-test-overview" */).then(c => wrapFunctional(c.default || c))
export const BatixTextileLabelling = () => import('../../components/batix/TextileLabelling.vue' /* webpackChunkName: "components/batix-textile-labelling" */).then(c => wrapFunctional(c.default || c))
export const CmsTBlock = () => import('../../components/cms/TBlock.vue' /* webpackChunkName: "components/cms-t-block" */).then(c => wrapFunctional(c.default || c))
export const CmsTBlockGroup = () => import('../../components/cms/TBlockGroup.vue' /* webpackChunkName: "components/cms-t-block-group" */).then(c => wrapFunctional(c.default || c))
export const CmsTPage = () => import('../../components/cms/TPage.vue' /* webpackChunkName: "components/cms-t-page" */).then(c => wrapFunctional(c.default || c))
export const IconsSearchResultPageIcon = () => import('../../components/icons/SearchResultPageIcon.vue' /* webpackChunkName: "components/icons-search-result-page-icon" */).then(c => wrapFunctional(c.default || c))
export const LayoutBaseSidebar = () => import('../../components/layout/BaseSidebar.vue' /* webpackChunkName: "components/layout-base-sidebar" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/layout/Footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/layout/Header.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutLogo = () => import('../../components/layout/Logo.vue' /* webpackChunkName: "components/layout-logo" */).then(c => wrapFunctional(c.default || c))
export const SageBasicInformation = () => import('../../components/sage/BasicInformation.vue' /* webpackChunkName: "components/sage-basic-information" */).then(c => wrapFunctional(c.default || c))
export const SageIframe = () => import('../../components/sage/SageIframe.vue' /* webpackChunkName: "components/sage-iframe" */).then(c => wrapFunctional(c.default || c))
export const UtilsOverlayLoader = () => import('../../components/utils/OverlayLoader.vue' /* webpackChunkName: "components/utils-overlay-loader" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderDetailsSidebar = () => import('../../components/account/orders/OrderDetailsSidebar.vue' /* webpackChunkName: "components/account-orders-order-details-sidebar" */).then(c => wrapFunctional(c.default || c))
export const AccountUtilsListImage = () => import('../../components/account/utils/ListImage.vue' /* webpackChunkName: "components/account-utils-list-image" */).then(c => wrapFunctional(c.default || c))
export const AccountUtilsListProductDetail = () => import('../../components/account/utils/ListProductDetail.vue' /* webpackChunkName: "components/account-utils-list-product-detail" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTAsyncCalendlyScript = () => import('../../components/cms/blocks/TAsyncCalendlyScript.vue' /* webpackChunkName: "components/cms-blocks-t-async-calendly-script" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTBasePicture = () => import('../../components/cms/blocks/TBasePicture.vue' /* webpackChunkName: "components/cms-blocks-t-base-picture" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTButton = () => import('../../components/cms/blocks/TButton.vue' /* webpackChunkName: "components/cms-blocks-t-button" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTCalendlyScript = () => import('../../components/cms/blocks/TCalendlyScript.vue' /* webpackChunkName: "components/cms-blocks-t-calendly-script" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTContent = () => import('../../components/cms/blocks/TContent.vue' /* webpackChunkName: "components/cms-blocks-t-content" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTFaircommerceQuote = () => import('../../components/cms/blocks/TFaircommerceQuote.vue' /* webpackChunkName: "components/cms-blocks-t-faircommerce-quote" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTFaq = () => import('../../components/cms/blocks/TFaq.vue' /* webpackChunkName: "components/cms-blocks-t-faq" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTFaqItem = () => import('../../components/cms/blocks/TFaqItem.vue' /* webpackChunkName: "components/cms-blocks-t-faq-item" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTHbMembershipsTable = () => import('../../components/cms/blocks/THbMembershipsTable.vue' /* webpackChunkName: "components/cms-blocks-t-hb-memberships-table" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTHeading = () => import('../../components/cms/blocks/THeading.vue' /* webpackChunkName: "components/cms-blocks-t-heading" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTIconList = () => import('../../components/cms/blocks/TIconList.vue' /* webpackChunkName: "components/cms-blocks-t-icon-list" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTIconListItem = () => import('../../components/cms/blocks/TIconListItem.vue' /* webpackChunkName: "components/cms-blocks-t-icon-list-item" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTInfoBox = () => import('../../components/cms/blocks/TInfoBox.vue' /* webpackChunkName: "components/cms-blocks-t-info-box" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTMultiColumnList = () => import('../../components/cms/blocks/TMultiColumnList.vue' /* webpackChunkName: "components/cms-blocks-t-multi-column-list" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTOrderList = () => import('../../components/cms/blocks/TOrderList.vue' /* webpackChunkName: "components/cms-blocks-t-order-list" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTPicture = () => import('../../components/cms/blocks/TPicture.vue' /* webpackChunkName: "components/cms-blocks-t-picture" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTProcess = () => import('../../components/cms/blocks/TProcess.vue' /* webpackChunkName: "components/cms-blocks-t-process" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTProcessItem = () => import('../../components/cms/blocks/TProcessItem.vue' /* webpackChunkName: "components/cms-blocks-t-process-item" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTPurchaseProduct = () => import('../../components/cms/blocks/TPurchaseProduct.vue' /* webpackChunkName: "components/cms-blocks-t-purchase-product" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTSectionCard = () => import('../../components/cms/blocks/TSectionCard.vue' /* webpackChunkName: "components/cms-blocks-t-section-card" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTShopTable = () => import('../../components/cms/blocks/TShopTable.vue' /* webpackChunkName: "components/cms-blocks-t-shop-table" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTSubscriptionManagement = () => import('../../components/cms/blocks/TSubscriptionManagement.vue' /* webpackChunkName: "components/cms-blocks-t-subscription-management" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTText = () => import('../../components/cms/blocks/TText.vue' /* webpackChunkName: "components/cms-blocks-t-text" */).then(c => wrapFunctional(c.default || c))
export const CmsBlocksTYoutubeVideo = () => import('../../components/cms/blocks/TYoutubeVideo.vue' /* webpackChunkName: "components/cms-blocks-t-youtube-video" */).then(c => wrapFunctional(c.default || c))
export const CmsFormsAccountForm = () => import('../../components/cms/forms/AccountForm.vue' /* webpackChunkName: "components/cms-forms-account-form" */).then(c => wrapFunctional(c.default || c))
export const CmsFormsAddressForm = () => import('../../components/cms/forms/AddressForm.vue' /* webpackChunkName: "components/cms-forms-address-form" */).then(c => wrapFunctional(c.default || c))
export const CmsFormsContactForm = () => import('../../components/cms/forms/ContactForm.vue' /* webpackChunkName: "components/cms-forms-contact-form" */).then(c => wrapFunctional(c.default || c))
export const CmsFormsFaircommerceForm = () => import('../../components/cms/forms/FaircommerceForm.vue' /* webpackChunkName: "components/cms-forms-faircommerce-form" */).then(c => wrapFunctional(c.default || c))
export const CmsFormsLoginDataForm = () => import('../../components/cms/forms/LoginDataForm.vue' /* webpackChunkName: "components/cms-forms-login-data-form" */).then(c => wrapFunctional(c.default || c))
export const CmsFormsSmsService = () => import('../../components/cms/forms/SmsService.vue' /* webpackChunkName: "components/cms-forms-sms-service" */).then(c => wrapFunctional(c.default || c))
export const CmsMembershipTableDesktop = () => import('../../components/cms/membership-table/MembershipTableDesktop.vue' /* webpackChunkName: "components/cms-membership-table-desktop" */).then(c => wrapFunctional(c.default || c))
export const CmsMembershipTableMembershipsTableMobile = () => import('../../components/cms/membership-table/MembershipsTableMobile.vue' /* webpackChunkName: "components/cms-membership-table-memberships-table-mobile" */).then(c => wrapFunctional(c.default || c))
export const CmsMembershipTableNoticeIcon = () => import('../../components/cms/membership-table/NoticeIcon.vue' /* webpackChunkName: "components/cms-membership-table-notice-icon" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavbarToolsBase = () => import('../../components/header/navbar-tools/Base.vue' /* webpackChunkName: "components/header-navbar-tools-base" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavbarToolsContact = () => import('../../components/header/navbar-tools/Contact.vue' /* webpackChunkName: "components/header-navbar-tools-contact" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavbarToolsDesktopSearch = () => import('../../components/header/navbar-tools/DesktopSearch.vue' /* webpackChunkName: "components/header-navbar-tools-desktop-search" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavbarToolsMobileSearch = () => import('../../components/header/navbar-tools/MobileSearch.vue' /* webpackChunkName: "components/header-navbar-tools-mobile-search" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavbarToolsMobileSearchModal = () => import('../../components/header/navbar-tools/MobileSearchModal.vue' /* webpackChunkName: "components/header-navbar-tools-mobile-search-modal" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavbarToolsSearchInput = () => import('../../components/header/navbar-tools/SearchInput.vue' /* webpackChunkName: "components/header-navbar-tools-search-input" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavbarToolsSearchSuggestion = () => import('../../components/header/navbar-tools/SearchSuggestion.vue' /* webpackChunkName: "components/header-navbar-tools-search-suggestion" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavbarToolsUser = () => import('../../components/header/navbar-tools/User.vue' /* webpackChunkName: "components/header-navbar-tools-user" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavbarToolsSearchForm = () => import('../../components/header/navbar-tools/_SearchForm.vue' /* webpackChunkName: "components/header-navbar-tools-search-form" */).then(c => wrapFunctional(c.default || c))
export const LayoutNavigationSidebarMenuItem = () => import('../../components/layout/NavigationSidebar/MenuItem.vue' /* webpackChunkName: "components/layout-navigation-sidebar-menu-item" */).then(c => wrapFunctional(c.default || c))
export const LayoutNavigationSidebar = () => import('../../components/layout/NavigationSidebar/NavigationSidebar.vue' /* webpackChunkName: "components/layout-navigation-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CmsContentNewsletterArchiveItem = () => import('../../components/cms/content/newsletter_archive_table/NewsletterArchiveItem.vue' /* webpackChunkName: "components/cms-content-newsletter-archive-item" */).then(c => wrapFunctional(c.default || c))
export const CmsContentNewsletterArchiveTable = () => import('../../components/cms/content/newsletter_archive_table/NewsletterArchiveTable.vue' /* webpackChunkName: "components/cms-content-newsletter-archive-table" */).then(c => wrapFunctional(c.default || c))
export const CmsContentYouthProtectionLogoForm = () => import('../../components/cms/content/youth_protection/YouthProtectionLogoForm.vue' /* webpackChunkName: "components/cms-content-youth-protection-logo-form" */).then(c => wrapFunctional(c.default || c))
export const CmsContentYouthProtectionLogoModal = () => import('../../components/cms/content/youth_protection/YouthProtectionLogoModal.vue' /* webpackChunkName: "components/cms-content-youth-protection-logo-modal" */).then(c => wrapFunctional(c.default || c))
export const CmsContentYouthProtectionLogoModalButton = () => import('../../components/cms/content/youth_protection/YouthProtectionLogoModalButton.vue' /* webpackChunkName: "components/cms-content-youth-protection-logo-modal-button" */).then(c => wrapFunctional(c.default || c))
export const CmsFormsServiceDeskRequestList = () => import('../../components/cms/forms/service-desk/ServiceDeskRequestList.vue' /* webpackChunkName: "components/cms-forms-service-desk-request-list" */).then(c => wrapFunctional(c.default || c))
export const CmsFormsMailAccountBlockingButton = () => import('../../components/cms/forms/mail/account-blocking/AccountBlockingButton.vue' /* webpackChunkName: "components/cms-forms-mail-account-blocking-button" */).then(c => wrapFunctional(c.default || c))
export const CmsFormsMailAccountBlockingForm = () => import('../../components/cms/forms/mail/account-blocking/AccountBlockingForm.vue' /* webpackChunkName: "components/cms-forms-mail-account-blocking-form" */).then(c => wrapFunctional(c.default || c))
export const CmsFormsMailLegalAdviceButton = () => import('../../components/cms/forms/mail/legal-advice/LegalAdviceButton.vue' /* webpackChunkName: "components/cms-forms-mail-legal-advice-button" */).then(c => wrapFunctional(c.default || c))
export const CmsFormsMailLegalAdviceForm = () => import('../../components/cms/forms/mail/legal-advice/LegalAdviceForm.vue' /* webpackChunkName: "components/cms-forms-mail-legal-advice-form" */).then(c => wrapFunctional(c.default || c))
export const CmsFormsMailShopauskunftButton = () => import('../../components/cms/forms/mail/shopauskunft/ShopauskunftButton.vue' /* webpackChunkName: "components/cms-forms-mail-shopauskunft-button" */).then(c => wrapFunctional(c.default || c))
export const CmsFormsMailShopauskunftForm = () => import('../../components/cms/forms/mail/shopauskunft/ShopauskunftForm.vue' /* webpackChunkName: "components/cms-forms-mail-shopauskunft-form" */).then(c => wrapFunctional(c.default || c))
export const CmsFormsMailWarningLetterButton = () => import('../../components/cms/forms/mail/warning-letter/WarningLetterButton.vue' /* webpackChunkName: "components/cms-forms-mail-warning-letter-button" */).then(c => wrapFunctional(c.default || c))
export const CmsFormsMailWarningLetterForm = () => import('../../components/cms/forms/mail/warning-letter/WarningLetterForm.vue' /* webpackChunkName: "components/cms-forms-mail-warning-letter-form" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
