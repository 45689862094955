







































































import { defineComponent } from '@nuxtjs/composition-api'
import Base from './Base.vue'
import { useAuth } from '@/hooks/auth'
import { useNav } from '@/hooks/nav'

export default defineComponent({
    name: 'User',
    components: {
        Base,
    },
    setup() {
        const { memberId, userAddress, userEmail } = useAuth()
        const { profileMenuItems } = useNav()
        const ariaId = 'header_user_dropdown'
        const company = userAddress.value?.company

        return {
            ariaId,
            memberId,
            company,
            userEmail,
            profileMenuItems,
        }
    },
})
